import { observer } from 'mobx-react-lite'
import { stringify } from 'qs'
import type { ComponentType, FC } from 'react'
import { createElement, useCallback, useMemo } from 'react'
import { inject } from 'regexparam'
import { Redirect, Route, useLocation } from 'wouter'
import { navigate } from 'wouter/use-browser-location'

import type { RouteParams } from '@/configs/routes'
import { routes } from '@/configs/routes'
import { NotFoundScreen } from '@/screens/404Screen'
import { logCreateRouteStack, logRoute } from '@/utils/logs'
import { getObjectEntries } from '@/utils/objects'

export type RouteDef = {
  path: string
  component?: ComponentType
  routes?: Record<string, RouteDef>
  renderConditionFn?: RouteRenderCondition
  redirectFn?: RouteRedirect
}

type GetRouteNames<T extends Record<string, RouteDef>> =
  T extends Record<string, RouteDef>
    ? {
        [K in keyof T]: T[K] extends RouteDef
          ? K | (T[K]['routes'] extends Record<string, RouteDef> ? GetRouteNames<T[K]['routes']> : never)
          : never
      }[keyof T]
    : never

export type RouteNames = GetRouteNames<typeof routes>

export type GetRouteParams<T extends RouteNames> = RouteParams[T] extends undefined
  ? [] | [{ searchParams?: any }]
  : [RouteParams[T] & { searchParams?: any }]

export type GetRoutePath<T extends RouteNames = RouteNames> = T extends RouteNames
  ? RouteParams[T] extends undefined
    ? { name: T; params?: { searchParams?: any } | undefined }
    : { name: T; params: RouteParams[T] & { searchParams?: any } }
  : never

/**
 * Determines how to handle a route rendering based on a condition.
 * @returns `true` to render the route, `false` to skip rendering, or an `{ redirectTo: string }` object to redirect to another route.
 */
export type RouteRenderConditionResponse = false | true | { redirectTo: string }
export type RouteRenderCondition = undefined | (() => RouteRenderConditionResponse)

/**
 * Determines how to handle a route redirect based on a condition.
 * @returns `false` to skip redirecting, or an `{ redirectTo: string }` object to redirect to another route.
 */
export type RouteRedirectResponse = false | { redirectTo: string }
export type RouteRedirect = undefined | (() => RouteRedirectResponse)

const flatRoutes = flattenRoutes(routes)

function flattenRoutes(
  routes: NonNullable<RouteDef['routes']>,
  _parent?: {
    parentPath?: RouteDef['path']
    parentRenderConditionFn?: RouteDef['renderConditionFn']
  },
): Record<RouteNames, Omit<RouteDef, 'routes'>> {
  const parentPath = _parent?.parentPath || ''
  const parentRenderConditionFn = _parent?.parentRenderConditionFn

  const routeEntries = getObjectEntries(routes)

  let flattened: Partial<Record<RouteNames, Omit<RouteDef, 'routes'>>> = {}

  for (const [name, def] of routeEntries) {
    const { routes, ...restDef } = def

    const path = [parentPath, restDef.path]
      .join('')
      .replace(/\/\//g, '/')
      .replace(/\/(?<!^\/)$/g, '')

    const renderCondition = restDef.renderConditionFn ?? parentRenderConditionFn

    if (routes) {
      flattened = {
        ...flattened,
        ...flattenRoutes(routes, { parentPath: path, parentRenderConditionFn: renderCondition }),
      }
    }

    flattened[name as RouteNames] = { ...restDef, path, renderConditionFn: renderCondition }
  }

  return flattened as Record<RouteNames, Omit<RouteDef, 'routes'>>
}

export function createRouteStack(opts?: { debug?: boolean }) {
  const { debug = false } = opts ?? {}

  logCreateRouteStack(debug, flatRoutes)

  const defs = getObjectEntries(flatRoutes)

  return defs.map(([name, def]) => createElement(RouteWrapper, { key: name, ...def, name, debug }))
}

const RouteWrapper: FC<RouteDef & { name: RouteNames; debug: boolean }> = observer(function RouteWrapper(props) {
  const { debug, name, path, component, renderConditionFn, redirectFn } = props

  const useRenderCondition = useCallback(() => {
    if (renderConditionFn === undefined) {
      return true
    } else {
      return renderConditionFn()
    }
  }, [])

  const useRedirect = useCallback(() => {
    return redirectFn?.() ?? false
  }, [])

  const shouldRedirect = useRedirect()
  const shouldRender = useRenderCondition()

  const logRouteAction = useMemo(() => logRoute(debug, name, path, component, shouldRender, shouldRedirect), [])

  if (shouldRender === false) {
    logRouteAction('Skipping rendering route.')
    return createElement(NotFoundScreen)
  }

  if (typeof shouldRender === 'object') {
    logRouteAction('Redirecting to another route.')

    return createElement(Redirect, { to: shouldRender.redirectTo })
  }

  if (shouldRedirect) {
    logRouteAction('Redirecting to another route.')

    return createElement(Redirect, { to: shouldRedirect.redirectTo })
  }

  if (!component) {
    logRouteAction('No component defined.')
    return createElement(NotFoundScreen)
  }

  logRouteAction('Rendering route.')

  return createElement(Route, { path: path, component: component as any })
})

export function goBack() {
  if (window.history.length > 1) {
    window.history.back()
  }
}

export function getRouteByName(name: RouteNames): RouteDef {
  return flatRoutes[name]
}

export function getRoutePath<T extends RouteNames>(name: T, ...params: GetRouteParams<T>) {
  const route = getRouteByName(name)

  const { searchParams, ...routeParams } = params[0] ?? {}
  const searchParamsString = stringify(searchParams, { addQueryPrefix: true })

  return inject(route.path, routeParams) + searchParamsString
}

export function navigateTo<T extends RouteNames>(name: T, ...params: GetRouteParams<T>) {
  navigate(getRoutePath(name, ...params))
}

/**
 * Hook variant of `navigateTo`, but one that subscribes to useLocation.
 * This hook version can be useful when some part of the app needs location updates, but you can't use the Link component to navigate.
 */
export function useNavigateTo() {
  const [_, setLocation] = useLocation()

  return function navigateTo<T extends RouteNames>(name: T, ...params: GetRouteParams<T>) {
    setLocation(getRoutePath(name, ...params))
  }
}
