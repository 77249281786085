import { Alert, Button, Group, Stack } from '@mantine/core'
import { IconArrowUpDashed, IconDeviceFloppy, IconExclamationCircle } from '@tabler/icons-react'
import { useState } from 'react'
import { Link } from 'wouter'

import { useGlobalState } from '@/hooks/useGlobalState'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import { castZodFormToContentModule } from '@/utils/contentModuleForm'
import { isFieldsListValid } from '@/utils/forms'
import { getRoutePath, navigateTo } from '@/utils/navigation'
import { remove } from '@/utils/storage'

export function ActionsSection(props: CMFormSection) {
  const { api } = useGlobalState()
  const [alertError, setAlertError] = useState<string | null>(null)
  const [alertScrollTarget, setAlertScrollTarget] = useState<Parameters<typeof props.scrollToSection>[0] | null>(null)

  async function validateForm() {
    setAlertError(null)
    setAlertScrollTarget(null)

    const validationResult = props.moduleForm.validate()

    const moduleValid = isFieldsListValid(
      props.moduleForm,
      ['name', 'shortDescription', 'selectEsk', 'presentationMode', 'categories', 'mappings'],
      validationResult.errors,
    )

    if (!moduleValid) {
      setAlertError('The module contains errors or lacks required information. Please check the form for errors.')
      setAlertScrollTarget('requiredSettings')
      return { ok: false }
    }

    if (!props.stepsQuery.data?.length) {
      setAlertError('The module can not be submitted without any sections. Please add at least one section.')
      setAlertScrollTarget('contentSteps')
      return { ok: false }
    }

    if (!props.stepsQuery.data?.every(step => step.valid)) {
      setAlertError(
        'One or more sections contain errors or lack required information. Please check the sections for errors.',
      )
      setAlertScrollTarget('contentSteps')
      return { ok: false }
    }

    if (!props.cmResourcesQuery.data?.every(resource => resource.valid)) {
      setAlertError(
        'The module has one or more supplemental resources with missing information. Please check the resources for errors.',
      )
      setAlertScrollTarget('optionalSettings')
      return { ok: false }
    }

    // Temporary workaround to validate each Sections' resources before submitting the form.
    // This n+1 is necessary since sections currently do not return resources in the same query.
    // Using Tanstack to cache the resources query results.
    // A specific section's resource query will be invalidated when the user updates the resources for that section.
    // https://infinitered.slack.com/archives/C07MY1RQ63S/p1731382232997459?thread_ts=1731363620.308279&cid=C07MY1RQ63S
    const sectionsResources = (
      await Promise.all(
        props.stepsQuery.data?.map(step => {
          return api.queryClient.fetchQuery({
            queryKey: ['step', 'resources', step.stepId],
            queryFn: () => api.getResources({ step_id: step.stepId }),
          })
        }) ?? [],
      )
    )
      .flat()
      .map(d => ({ ...d, valid: !!d.title && !!d.description && !!d.data }))

    if (!sectionsResources.every(resource => resource.valid)) {
      setAlertError(
        "One or more sections have supplemental resources with missing information. Please check the sections' resources for errors.",
      )
      setAlertScrollTarget('contentSteps')
      return { ok: false }
    }

    return { ok: true }
  }

  async function updateCM(opts?: { validate?: boolean }) {
    const { validate = false } = opts || {}

    props.scrollToSection('actions')

    if (validate) {
      const { ok } = await validateForm()

      if (!ok) return
    }

    const values = props.moduleForm.getValues()
    const firstStepId = props.stepsQuery.data?.[0]?.stepId

    // First update the module
    await props.cmUpdateMutation.mutateAsync(
      { ...castZodFormToContentModule(values), task_id: props.taskId! },
      {
        onSettled: () => {
          remove(`@sidekick/forms/cm/${values.taskId}`)
        },
      },
    )
    // TODO: This is a temporary work around, should be removed when the form is redesigned
    // Then update the first step's objectives if they exist
    if (firstStepId && props.stepForm.isDirty('section_objectives')) {
      await props.stepUpdateMutation.mutateAsync(
        {
          step_id: firstStepId,
          // Include other required fields from the existing step
          ...Object.fromEntries(
            Object.entries(props.stepsQuery.data![0]).map(([key, value]) => [key, value ?? undefined])
          ),
          section_objectives: props.stepForm.getValues().section_objectives,
        },
        {
          onSettled: () => {
            navigateTo('contentModules')
          },
        }
      )
    } else {
      navigateTo('contentModules')
    }
  }

  const isCMDraft = props.cmQuery.data?.status === 'draft'
  const isMutating = props.cmUpdateMutation.isPending

  return (
    <Stack gap="xl">
      {!!alertError && (
        <Alert
          variant="filled"
          color="red"
          radius="md"
          title="Could Not Submit Form"
          icon={<IconExclamationCircle stroke={1.5} />}>
          <Stack align="flex-start">
            {alertError}

            {!!alertScrollTarget && (
              <Button
                size="xs"
                variant="filled"
                color="red"
                bg="rgba(255, 255, 255, 0.3)"
                onClick={() => props.scrollToSection(alertScrollTarget)}
                leftSection={<IconArrowUpDashed stroke={2} />}>
                Scroll to Error
              </Button>
            )}
          </Stack>
        </Alert>
      )}

      <Group gap="xl">
        <Button
          leftSection={<IconDeviceFloppy />}
          onClick={() => updateCM({ validate: !isCMDraft })}
          loading={isMutating}>
          Save Module
        </Button>

        {!isMutating && (
          <Button component={Link} to={getRoutePath('contentModules')} size="sm" variant="light" color="gray">
            Close
          </Button>
        )}
      </Group>
    </Stack>
  )
}
