import type { HelpSection } from './helpContent.types'

export const helpContent: HelpSection[] = [
  {
    title: 'General',
    subSections: [
      {
        entries: [
          {
            question: 'What is the Content Portal?',
            answer:
              'The Content Portal is an interface that allows Subject Matter Experts to design Content Modules and distribute them to the Members of their Sponsor organization.',
          },
          {
            question: 'What is a Content Module?',
            answer:
              'A Content Module is a collection of materials about a subject that are stored together to be presented to the Members.',
          },
          {
            question: 'What is an Expert Sidekick?',
            answer:
              'An Expert Sidekick is knowledgeable about specific topics as defined by a Subject Matter Expert. Their goal is to help the Member gain knowledge in the Subject Matter Expert’s field.',
          },
          {
            question: 'What is a Member Sidekick?',
            answer:
              'A Member Sidekick is the Member’s best friend, confidant, concierge, all in their pocket. They help the Member figure out what they need to do, and who they need to speak to so they can get their task done. ',
          },
          {
            question: 'How do I create a new Content Module?',
            answer: 'On the Content Module screen on the right side of the screen, click the New Module button.',
          },
        ],
      },
    ],
  },
  {
    title: 'Creating a Content Module',
    subSections: [
      {
        title: 'Step 1: Add Primary Content',
        entries: [
          {
            question: 'What is Primary Content?',
            answer: 'Primary content is the information the Expert Sidekick accesses to give to the member.',
          },
          {
            question: 'How do I add my material as Primary Content?',
            answer: 'You can upload a file or copy-paste plain text into the box.',
          },
          {
            question: 'What file formats can I upload?',
            answer:
              'The file formats for the main content and supplemental text are: .txt, .docx, and .pdf. Supplemental image formats are: .png, .jpeg, .gif.',
          },
          {
            question: 'Why do I need to enter my Primary Content before doing all the other stuff?',
            answer:
              'The Primary Content is uploaded first to allow the AI to process it and help you fill out the other fields.',
          },
        ],
      },
      {
        title: 'Step 2: Describe Module',
        entries: [
          {
            question: 'Why do we have to describe the Content Module? What do all these fields do?',
            answer:
              'Describing the Content Module accurately helps the Sidekicks bring up the relevant information at the right time in the conversation with the Member.',
          },
          {
            question: 'Can I change what the AI suggests for each field?',
            answer:
              'Yes. You can edit the Sidekick’s suggestions simply by clicking in the field and entering new text.',
          },
        ],
      },
      {
        title: 'Step 3: Divide Module into Sections and Set Up Section',
        entries: [
          {
            question: 'What’s the deal with the Sections inside each Content Module?',
            answer:
              'In the long term, Sections will enable you to create a structured experience for Members. However, at the current time, we only support a creating one large section for best performance.',
          },
          {
            question: 'Why do I need FAQs?',
            answer:
              'FAQs are helpful for Members and Sidekicks. Members can ask for FAQs if they aren’t sure what to ask. Sidekicks have easy access to some of the most common questions so they can answer them quickly.',
          },
          {
            question: 'Do I have to accept all the FAQs the Sidekick generates?',
            answer:
              'No, they are suggestions. You can accept some or all, and edit any questions and answers that aren’t quite right.',
          },
          {
            question: 'What are Supplemental Resources and why are they important?',
            answer:
              'You can add Supplemental Resources—like links, images, or videos, or extra text—to extend your Primary Content. These Resources will be shown by the Expert Sidekick shown when relevant in the context of a conversation. Supplemental text is used to provide additional information to the Expert Sidekick.',
          },
        ],
      },
      {
        title: 'Step 4: Save Module',
        entries: [
          {
            question: 'Will my Content Module become available to Members when I save it?',
            answer:
              'No. Saving your Content Module will not make it available to Member. It will only be available to them when you Publish your Content Module from the Modules screen under My Modules.',
          },
        ],
      },
    ],
  },
  {
    title: 'Managing a Content Module',
    subSections: [
      {
        entries: [
          {
            question: 'I’ve created a Content Module, but it says Draft. What do I do now?',
            answer: 'If you are ready for people to see your Content Module, you can publish it.',
            steps: [
              'On the Content Module Screen, at the  far right side of the Content Module, click the globe icon.',
              'From the dropdown list, click Publish. Your content module will begin processing and will be available as soon as it’s done.',
            ],
          },
          {
            question: 'Can I edit a Content Module?',
            answer: 'You can absolutely go back and edit your content!',
            steps: [
              'On the Modules screen, to the left of the content module, click the pencil icon to get into Edit mode.',
              'Modify your content as you see fit.',
              'When ready, click Submit for Approval. Your content will be available immediately.',
            ],
          },
          {
            question: 'Why can’t I edit all the Content Modules?',
            answer: 'Content Module editing is restricted to the owner of the Content Module.',
          },
          {
            question: 'Can I see Content Modules other Subject Matter Experts have created?',
            answer: 'Yes, you can view the content in a view-only mode.',
          },
          {
            question: 'Can I delete a Content Module?',
            answer:
              'Yes, you can delete a Content Module you own. However, please note your content will be deleted immediately, even if there are users interacting with the content.',
            steps: [
              'On the Content Module Screen, at the right side of the Content Module, click the globe icon.',
              'From the dropdown list, click Delete. Your content module will be deleted',
            ],
          },
        ],
      },
    ],
  },
]
