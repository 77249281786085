import { type __InputStylesNames, Button, Group, Loader, Text, type TextInputProps } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import type { JSXElementConstructor } from 'react'
import { cloneElement, type ReactElement, useEffect, useRef, useState } from 'react'

import { InputLabel } from '@/components/InputLabel'
import { theme } from '@/configs/theme'
import * as classes from '@/styles/AIInputWrapper.css'

import type { AIActionButtonProps } from './AIActionButton'

const AI_CHAT_BUTTON_NAME = 'AIActionButton'

type AIInputWrapperProps = {
  generating?: boolean
  children: ReactElement<TextInputProps>
  value?: string | null
  clearValue?: () => void
  disabled?: boolean
}

export function AIInputWrapper(props: AIInputWrapperProps) {
  // Input its props that we'll be modifying.
  const el = props.children
  const elProps = el.props

  // Handlers for when the value is being changed externally (e.g. via the SMESK).
  const valueBeingWatched = useRef<typeof elProps.value>(null)
  const [watchingForExternalValueChange, setWatchingForExternalValueChange] = useState(false)

  function watchForExternalValueChange() {
    valueBeingWatched.current = elProps.value
    setWatchingForExternalValueChange(true)
  }

  useEffect(() => {
    if (!watchingForExternalValueChange) return

    if (elProps.value !== valueBeingWatched.current) {
      props.clearValue?.()
      setWatchingForExternalValueChange(false)
      // @ts-expect-error -- Mantine onChange event accepts arbitrary values. Useful since we don't have to construct ChangeEvent instances.
      elProps.onChange?.(elProps.value ?? '')
    }
  }, [watchingForExternalValueChange, elProps.value])

  // Only modify the input if the metadata value exists and the input's value is empty
  if (!!elProps.value) return el
  if (props.disabled) return el
  if (!(props.generating || !!props.value)) return el

  // Hijack the AI button's click handler to watch for external value changes via the Chat interface.
  const aiActionButtonProp = getModifiedAIActionButtonProp({
    right: elProps.rightSection,
    left: elProps.leftSection,
    watchForExternalValueChange,
  })

  const classNames = elProps.classNames as Partial<Record<__InputStylesNames, string>>

  // Modify the props of the input.
  const modifiedProps: TextInputProps = {
    ...elProps,
    // If a user types in the input, we assume the metadata value is approved and apply the typed changes to the form.
    onChange: e => {
      elProps.onChange?.(e)
      props.clearValue?.()
    },
    value: props.value ?? '',
    withAsterisk: false,
    variant: 'filled',
    classNames: {
      ...elProps.classNames,
      input: theme.cx(classNames?.input, classes.input, elProps?.error && classes.inputError),
    },
    error: !!elProps?.error && 'AI generated content requires approval',
    ...aiActionButtonProp,
    label: (
      <Group justify="space-between" align="center">
        <InputLabel label={elProps.label} withAsterisk={elProps.withAsterisk} withSpacer={false} />

        <Group align="center" gap="xs">
          {props.generating && <Loader type="dots" size="md" color="indigo.4" h={10} />}

          <Text fw={600} tt="uppercase" size="sm" variant="gradient">
            {props.generating ? 'Generating' : 'AI Generated'}
          </Text>

          {!props.generating && (
            <Button
              size="compact-xs"
              variant="gradient"
              leftSection={<IconCheck stroke={1.5} size={16} />}
              // When a user clicks the approve button, we clear the metadata value and apply the changes to the form.
              onClick={() => {
                props.clearValue?.()
                // @ts-expect-error -- Mantine onChange event accepts arbitrary values. Useful since we don't have to construct ChangeEvent instances.
                elProps.onChange?.(props.value ?? '')
              }}>
              Approve
            </Button>
          )}
        </Group>
      </Group>
    ),
  }

  const modifiedChild = cloneElement(el, modifiedProps)

  return modifiedChild
}

// Helper function to retrieve the AI Action Button prop and modify its onClick handler.
function getModifiedAIActionButtonProp(props: {
  right: TextInputProps['rightSection']
  left: TextInputProps['leftSection']
  watchForExternalValueChange: () => void
}) {
  const rightSection = props.right as ReactElement<AIActionButtonProps>
  const leftSection = props.left as ReactElement<AIActionButtonProps>
  let prop: 'rightSection' | 'leftSection' | undefined
  let el: ReactElement<AIActionButtonProps> | undefined
  let elProps: AIActionButtonProps | undefined

  if ((rightSection?.type as JSXElementConstructor<any>)?.name === AI_CHAT_BUTTON_NAME) {
    prop = 'rightSection'
    el = rightSection
    elProps = rightSection.props
  } else if ((leftSection?.type as JSXElementConstructor<any>)?.name === AI_CHAT_BUTTON_NAME) {
    prop = 'leftSection'
    el = leftSection
    elProps = leftSection.props
  }

  if (!el || !prop) {
    return undefined
  }

  el = cloneElement(el, {
    ...elProps,
    onClick: e => {
      props.watchForExternalValueChange()
      elProps?.onClick?.(e)
    },
  })

  return { [prop]: el }
}
