import { getRequiredFieldDescriptionProps } from "@/utils/forms"
import { Avatar, Group, MultiSelect, Select, Stack, Text } from "@mantine/core"
import { Grid } from "@mantine/core"
import { useMemo } from "react"
import { CMFormSection } from "./ContentModuleFormScreen"
import { observer } from "mobx-react-lite"
// import { GetESKsResponse } from "@/services/api/api.types"
// import { ModuleFormSchema } from "@/utils/contentModuleForm"
// import { modals } from "@mantine/modals"
// import type { z } from 'zod'

import avatars from '@/assets/avatars'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import { theme } from "@/configs/theme"

export const TopicSettingsSection = observer(function TopicSettingsSection(props: CMFormSection) {
  const topic = props.moduleForm.getValues().categories[0]
  const topicOptions = useMemo(() => topicOptionsMapper(props.topicsQuery.data ?? []), [props.topicsQuery.data])
  // const eskOptions = useMemo(() => eskOptionsMapper(props.esksQuery.data ?? []), [props.esksQuery.data])

  const subTopics = useMemo(() => {
    const t = props.topicsQuery.data?.find(t => t.displayName === topic)
    if (!t) return []

    return topicOptionsMapper(t.subtopics)
  }, [topic])

  // const eskForTopic = useMemo(() => {
  //   const t = props.topicsQuery.data?.find(t => t.displayName === topic)
  //   if (!t) return null

  //   const esk = props.esksQuery.data?.find(e => e.topicId === t.id)
  //   return esk?.eskId ?? null
  // }, [props.esksQuery.data, props.topicsQuery.data, topic])

  const eskAvatar = useMemo(() => {
    if (!props.esksQuery.data) return avatars.placeholder

    const esk = props.esksQuery.data.find(e => e.eskId === props.moduleForm.getValues().selectEsk)

    return esk ? avatars[esk.avatarId] : avatars.placeholder
  }, [props.moduleForm.getValues().selectEsk, props.esksQuery.data])

  const hasSubTopics = subTopics.length > 0
  // const hasCategories = props.moduleForm.getValues().categories.length > 0

  return (
    <Grid gutter="xxxl">
      <Grid.Col span={4}>
        <Select
          withAsterisk
          clearable
          label="Topic"
          data={topicOptions}
          {...props.moduleForm.getInputProps('categories')}
          value={topic ?? null}
          // TODO: eventually this will support selecting multiple categories
          {...getRequiredFieldDescriptionProps(
            props.moduleForm,
            'categories',
            'Select the topic your module is about.',
          )}
          onChange={v => {
            props.moduleForm.setFieldValue('mappings', [])

            const topic = props.topicsQuery.data?.find(t => t.displayName === v)

            if (!v || !topic) {
              props.moduleForm.setFieldValue('categories', [])
              props.moduleForm.setFieldValue('selectEsk', '')
            } else {
              props.moduleForm.setFieldValue('categories', [topic.displayName])

              const esk = props.esksQuery.data?.find(e => e.topicId === topic.id)

              if (esk) {
                props.moduleForm.setFieldValue('selectEsk', esk.eskId)
              }
            }
          }}
        />
      </Grid.Col>

      <Grid.Col span={4}>
        <MultiSelect
          withAsterisk
          label="Sub-Topics"
          data={subTopics}
          disabled={!hasSubTopics}
          {...props.moduleForm.getInputProps('mappings')}
          {...getRequiredFieldDescriptionProps(
            props.moduleForm,
            'mappings',
            'Select sub-topics within selected topic.',
          )}
        />
      </Grid.Col>

      <Grid.Col span={4}>
        <Group gap="lg">
          <Avatar
            variant="transparent"
            className={theme.cx(classes.eskAvatarBase, !!eskAvatar && classes.eskAvatar)}
            radius="sm"
            size="lg"
            src={eskAvatar}
          />

          <Stack gap={2} flex={1}>
            <Text size="sm">Expert Sidekick</Text>
            <Text size="lg" fw={500}>
              {props.esksQuery.data?.find(e => e.eskId === props.moduleForm.getValues().selectEsk)?.name ?? 'No Expert Sidekick'}
            </Text>
          </Stack>

          {/* <Select
            flex={1}
            label="Expert Sidekick"
            data={eskOptions}
            disabled={true}
            {...props.moduleForm.getInputProps('selectEsk')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'selectEsk',
              '', // 'Select an Expert Sidekick to present this module.'
            )}
            onChange={e => {
              if (eskForTopic && e !== eskForTopic) {
                modals.openConfirmModal({
                  title: 'Are you sure?',
                  centered: true,
                  children: (
                    <Text size="sm">{`The area of expertise for the Expert Sidekick you have chosen is not "${props.moduleForm.getValues().categories?.[0]}". Would you like to proceed?`}</Text>
                  ),
                  labels: { confirm: 'Yes', cancel: 'Cancel' },
                  groupProps: { mt: 'xl' },
                  onConfirm: () => {
                    props.moduleForm.setFieldValue('selectEsk', e as z.infer<ModuleFormSchema>['selectEsk'])
                  },
                })
              } else {
                props.moduleForm.setFieldValue('selectEsk', e as z.infer<ModuleFormSchema>['selectEsk'])
              }
            }}
          /> */}
        </Group>
      </Grid.Col>
    </Grid>
  )
})

// function eskOptionsMapper(data: GetESKsResponse) {
//   return data.map(esk => ({ value: esk.eskId, label: esk.name ?? `Avatar ${esk.eskId}` }))
// }

function topicOptionsMapper(data: { displayName: string }[]) {
  return data.map(c => ({ value: c.displayName, label: c.displayName })).filter(c => !!c.label)
}
