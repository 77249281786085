import { Avatar, Box, Button, Group, Paper, Pill, Text } from '@mantine/core'
import { useMemo } from 'react'

import avatars from '@/assets/avatars'
import { CollapsibleContentSection } from '@/components/CollapsibleContentSection'
import { ComingSoon } from '@/components/ComingSoon'
import { TableContentGrid } from '@/components/TableContentGrid'
import { theme } from '@/configs/theme'

import { useContentModuleScreenData } from './ContentModuleScreen'
import { RadioContentCell } from './RadioContentCell'

export function ModuleSection() {
  const data = useContentModuleScreenData()

  const eskName = useMemo(
    () =>
      data.esksQuery.data?.find(e => e.eskId === data.cmQuery.data?.selectEsk)?.name ??
      `Avatar ${data.cmQuery.data?.selectEsk ?? ''}`,
    [data.cmQuery.data?.selectEsk, data.esksQuery.data],
  )

  const eskAvatar = useMemo(() => {
    if (!data.esksQuery.data) return undefined

    const esk = data.esksQuery.data.find(e => e.eskId === data.cmQuery.data?.selectEsk)

    return esk ? avatars[esk.avatarId] : undefined
  }, [data.cmQuery.data?.selectEsk, data.esksQuery.data])

  return (
    <CollapsibleContentSection
      id="module-details"
      title="Module Details"
      subtitle="General module information and user experience settings."
      ContentElement={
        <TableContentGrid
          headingFlexWidth="245px"
          rows={[
            { heading: 'Title', content: <Text fw={600}>{data.cmQuery.data?.title}</Text> },
            {
              heading: 'Primary Content',
              content: (
                <Box>
                  <Paper bg={theme.lighten(theme.colors.primaryColors[1], 0.7)} radius="sm" p="md" pb="xl" shadow="0">
                    <Text size="sm" fw={500} lineClamp={6}>
                      {data.cmContentQuery.data?.content}
                    </Text>
                  </Paper>
                  <Group justify="center">
                    <Button
                      size="sm"
                      style={{ marginTop: 'calc(var(--button-height-sm) / -2)' }}
                      onClick={() =>
                        data.modal.current?.openModal({
                          title: 'Previewing Primary Content',
                          data: data.cmContentQuery.data?.content,
                          opts: { markdown: true },
                        })
                      }>
                      View Full Content
                    </Button>
                  </Group>
                </Box>
              ),
            },
            {
              heading: 'Expert Sidekick',
              content: (
                <Group align="center">
                  <Avatar src={eskAvatar} variant={!!eskAvatar ? 'transparent' : 'light'} radius="sm" size="lg" />
                  <Text>{eskName}</Text>
                </Group>
              ),
            },
            { heading: 'Module Description', content: data.cmQuery.data?.shortDescription },
            { heading: 'Topic', content: data.cmQuery.data?.categories?.join(', ') },
            {
              heading: 'Sub-Topics',
              content: (
                <Group>
                  {data.cmQuery.data?.mappings?.map(m => (
                    <Pill key={m} size="lg">
                      {m}
                    </Pill>
                  ))}
                </Group>
              ),
            },
            {
              heading: 'Module Flow',
              content: (
                <RadioContentCell
                  items={[
                    { label: 'User Directed', selected: !data.cmQuery.data?.areStepSequential },
                    { label: 'Sequential', selected: !!data.cmQuery.data?.areStepSequential },
                  ]}
                />
              ),
            },
            { heading: 'Expiration Date', content: <ComingSoon /> },
            { heading: 'Due Date', content: <ComingSoon /> },
            { heading: 'Which segment of your population is this content for?', content: <ComingSoon /> },
            {
              heading:
                'Which media can the Expert Sidekick use in personalizing content delivery to member preferences?',
              content: <ComingSoon />,
            },
          ]}
        />
      }
    />
  )
}
