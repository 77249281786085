import { Box, Button, Group, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconMessages } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'

import { AIActionButton } from '@/components/AIActionButton'
import { InputLabel } from '@/components/InputLabel'
import { Table } from '@/components/Table'
import { useMockSMESKChatIntegration } from '@/hooks/useMockSMESKIntegration'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { FAQModalProps } from '@/screens/ContentModuleFormScreen/FAQModal'
import { FAQModal } from '@/screens/ContentModuleFormScreen/FAQModal'
import type { GetFAQsResponse } from '@/services/api/api.types'
import { castFAQToZodForm } from '@/utils/contentModuleForm'

interface FAQTableProps extends CMFormSection {
  type: 'cm' | 'step'
}

export function FAQTable(props: CMFormSection & FAQTableProps) {
  const { askSMESKForFAQs } = useMockSMESKChatIntegration(props)

  function openFAQModal(isNew: boolean) {
    props.openModal?.({
      title: `${isNew ? 'New' : 'Edit'} FAQ`,
      subtitle:
        props.type === 'cm'
          ? 'Add any FAQs for the module not covered under section FAQs.'
          : 'Add any FAQs for this section.',
      Component: FAQModal,
      ComponentProps: { type: props.type } satisfies FAQModalProps,
    })
  }

  function stageFAQ(faq?: GetFAQsResponse['0']) {
    props.faqForm.setValues(castFAQToZodForm(faq))
    props.faqForm.resetDirty()
    props.faqForm.resetTouched()
  }

  function deleteFAQ(faq: GetFAQsResponse['0']) {
    const idParams = props.type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }

    modals.openConfirmModal({
      title: 'Delete FAQ?',
      centered: true,
      children: <Text size="sm">{`Are you sure you want to delete this FAQ? This cannot be undone.`}</Text>,
      labels: { confirm: 'Delete FAQ', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      groupProps: { mt: 'xl' },
      onConfirm: () => {
        props.faqsDeleteMutation?.mutate({
          ...idParams,
          faq_ids: faq.faqId,
        })
      },
    })
  }

  const data = props.type === 'cm' ? props.cmFAQsQuery.data : props.stepFAQsQuery.data
  const label = props.type === 'cm' ? 'Module-Level FAQs' : 'Section FAQs'
  const description =
    props.type === 'cm'
      ? 'Add any FAQs for the module not covered under section FAQs.'
      : 'Add any FAQs for this section.'
  const emptyDescription =
    props.type === 'cm'
      ? 'Add any FAQs not covered in the primary content files. You can have Sidekick auto generate a list that you can edit, you can upload a document of FAQs, or you can manually add FAQs.'
      : 'You can have Sidekick auto generate an FAQ list that you can edit, or you can enter FAQs manually.'

  return (
    <Box>
      <InputLabel label={label} description={description} withDivider />

      <Table
        enableTableHead={false}
        enableTopToolbar={false}
        data={data ?? []}
        columns={faqColumnDefs}
        rowActionsInclude={['edit', 'delete']}
        rowActionsTooltipLabels={{
          edit: `Edit`,
          delete: 'Permanently Delete',
        }}
        onRowActionClick={(action, row) => {
          if (action === 'edit') {
            stageFAQ(row.original)
            openFAQModal(false)
          } else if (action === 'delete') {
            deleteFAQ(row.original)
          }
        }}
        EmptyStateProps={{
          title: 'No FAQs Added',
          description: emptyDescription,
          Icon: IconMessages,
        }}
        displayColumnDefOptions={{ 'mrt-row-actions': { size: 98 } }}
      />

      <Group justify="center" mt="xl" gap="xl">
        <AIActionButton variant="button" onClick={() => askSMESKForFAQs({ type: props.type })} />

        {/* <Button>Upload FAQs From Doc</Button> */}

        <Button
          variant="light"
          onClick={() => {
            stageFAQ()
            openFAQModal(true)
          }}>
          Manually Add FAQ
        </Button>
      </Group>
    </Box>
  )
}

const faqColumnDefs: MRT_ColumnDef<GetFAQsResponse['0']>[] = [
  {
    id: 'faq',
    header: 'FAQ',
    grow: true,
    Cell: props => {
      return (
        <Box>
          <Text size="sm" fw={500} lineClamp={2}>
            {props.row.original.question}
          </Text>
          <Text size="sm" c="dimmed" lineClamp={2}>
            {props.row.original.answer}
          </Text>
        </Box>
      )
    },
  },
]
