import { getEnv, type IStateTreeNode } from 'mobx-state-tree'

import type { RootStoreEnvironment } from '@/services/state/RootStore'

export function withEnv<T extends IStateTreeNode>(mstInstance: T) {
  const env = getEnv(mstInstance) as RootStoreEnvironment

  return {
    views: {
      get api() {
        return env.api
      },
      get analyticsApi() {
        return env.analyticsApi
      },
    },
  }
}
