import { Badge, Box, Group, Loader, Menu, Paper, Text, Title } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconArchive, IconClockShare, IconHexagons, IconSend2, IconSettingsCog, IconTrash } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { createMRTColumnHelper } from 'mantine-react-table'
import { observer } from 'mobx-react-lite'
import { usePostHog } from 'posthog-js/react'
import { createContext, Fragment, useContext, useEffect, useMemo } from 'react'

import { Screen } from '@/components/Screen'
import type { TableProps } from '@/components/Table'
import { HeaderCellWithTooltip, Table, TableRowActionButton } from '@/components/Table'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { ContentModuleStatus, GetContentModulesResponse, GetESKsResponse } from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'
import { useNavigateTo } from '@/utils/navigation'

type TableContext = {
  esks: GetESKsResponse
  changeModuleStatus: (data: GetContentModulesResponse[0], status: `${ContentModuleStatus}`) => void
}
const TableContext = createContext<TableContext>({} as TableContext)
const useTableContext = () => useContext(TableContext)

export const ContentModulesScreen = observer(function ContentModulesScreen() {
  const { api, uiStore, devStore } = useGlobalState()
  const posthog = usePostHog()

  useEffect(() => {
    posthog?.capture('$pageview', { skPlatform: 'portal' })
  }, [posthog])
  const navigateTo = useNavigateTo()

  const esksQuery = useQuery({
    queryKey: ['cm', 'esks'],
    queryFn: api.getESKs,
  })

  const cmQuery = useQuery({
    queryKey: ['cm', 'list'],
    queryFn: api.getContentModules,
    initialData: [],
    enabled: esksQuery.isSuccess,
    refetchInterval: 7 * 1000,
  })

  const cmChangeStatusMutation = useMutation({
    mutationFn: api.changeContentModuleStatus,
    onMutate: () => uiStore.showLoadingOverlay(),
    onSettled: () => uiStore.hideLoadingOverlay(),
    onSuccess: (d, _vars) => {
      const success = 'status' in d && d.status
      const error = 'errors' in d

      if (success) {
        api.queryClient.invalidateQueries({ 
          queryKey: ['cm', 'list'],
          delay: 3000 // 3 seconds delay
        })

        // DISABLED: As per request from Jeff: https://infinitered.slack.com/archives/C07MY1RQ63S/p1734730558931089?thread_ts=1734729429.572509&cid=C07MY1RQ63S
        // notifications.show({
        //   title: 'Module Status Updated',
        //   message: d.message ?? `The module's status is now "${vars.status}".`,
        //   color: 'green',
        //   classNames: commonClasses.notificationFilled,
        //   position: 'bottom-left',
        // })
      } else if (error) {
        const errorMessage =
          d.errors?.map((e, i) => (
            <Fragment key={i}>
              <br />
              {e.error}
            </Fragment>
          )) ?? 'There was an issue attempting to update the module status. Please try again or contact support.'

        notifications.show({
          title: 'Could Not Update Module Status',
          message: errorMessage,
          color: 'red',
          classNames: commonClasses.notificationFilled,
          position: 'bottom-left',
        })
      }
    },
    onError: () => {
      notifications.show({
        title: 'Could Not Update Module Status',
        message: 'There was an issue attempting to update the module status. Please try again or contact support.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    },
  })

  function changeModuleStatus(data: GetContentModulesResponse[0], status: `${ContentModuleStatus}`) {
    const mutate = () => cmChangeStatusMutation.mutate({ task_id: data.taskId!, status })

    if (status === 'published') {
      mutate()
      return
    }

    if (status === 'draft' || status === 'removed' || status === 'testing') {
      const title = status === 'draft' ? 'Unpublish Module?' : status === 'testing' ? 'Test Module?' : 'Delete Module?'
      const message =
        status === 'draft'
          ? 'Are you sure you want to change this module\'s status to "Draft"?'
          : status === 'testing'
            ? 'You—and only you—can now interact with your content in the Sidekick app. No one else will be able to interact with this content until you publish it.'
            : 'Are you sure you want to delete this module? This action cannot be undone.'
      const color = status === 'draft' ? 'orange' : status === 'testing' ? undefined : 'red'
      const label = status === 'draft' ? 'Change to Draft' : status === 'testing' ? 'Test in App' : 'Delete Module'

      modals.openConfirmModal({
        title,
        centered: true,
        children: <Text size="sm">{message}</Text>,
        labels: { confirm: label, cancel: 'Cancel' },
        confirmProps: { color },
        groupProps: { mt: 'xl' },
        onConfirm: mutate,
      })

      return
    }

    if (status === 'pending_approval') {
      modals.openConfirmModal({
        title: 'Ready to Submit?',
        centered: true,
        children: <Text size="sm">{`Are you ready to submit for approval?`}</Text>,
        labels: { confirm: 'Yes', cancel: 'Cancel' },
        groupProps: { mt: 'xl' },
        onCancel: modals.closeAll,
        onConfirm: () => {
          modals.openConfirmModal({
            title: 'Copyright Confirmation',
            centered: true,
            children: (
              <Text size="sm">{`I verify that all the information provided for this module belongs to me and my organization or is used with permission from the owner.`}</Text>
            ),
            labels: { confirm: 'Confirm and Submit', cancel: 'Cancel' },
            groupProps: { mt: 'xl' },
            onCancel: modals.closeAll,
            onConfirm: mutate,
          })
        },
      })
    }
  }

  const data = useMemo(
    () => (devStore.showRemovedModules ? cmQuery.data : cmQuery.data.filter(d => d.status !== 'removed')),
    [cmQuery.data, devStore.showRemovedModules],
  )

  const CommonTableProps: Omit<TableProps<GetContentModulesResponse[0]>, 'data' | 'columns'> = {
    entity: { singular: 'Module', plural: 'Modules', Icon: IconHexagons },
    enableSorting: true,
    initialState: { sorting: [{ id: 'title', desc: false }] },
    positionActionsColumn: 'first',
    EmptyStateProps: { py: 'xl' },
    onRowActionClick: (action, row) => {
      if (action === 'edit') {
        posthog?.capture('content_module_edit', { taskId: row.original.taskId!, skPlatform: 'portal' })
        navigateTo('contentModuleFormEdit', { id: row.original.taskId! })
      } else if (action === 'view') {
        posthog?.capture('content_module_view', { taskId: row.original.taskId!, skPlatform: 'portal' })
        navigateTo('contentModule', { id: row.original.taskId! })
      }
    },
    onToolbarActionClick: action => {
      if (action === 'new') {
        navigateTo('contentModuleFormNew')
      }
    },
  }

  return (
    <Screen title="Modules" icon={IconHexagons} BodyStackProps={{ gap: 'xxxl' }}>
      <TableContext.Provider value={{ changeModuleStatus, esks: esksQuery.data ?? [] }}>
        <Paper radius="md" p="lg" shadow="sm">
          <Title order={5} mb="xl">
            My Modules
          </Title>

          <Table
            data={data.filter(d => d.editable !== false)}
            columns={cols}
            toolbarActionsInclude={['new']}
            rowActionsInclude={['edit', 'view']}
            rowActionsTooltipLabels={{ edit: 'Edit Module', view: 'View module' }}
            displayColumnDefOptions={{ 'mrt-row-actions': { size: 98 } }}
            {...CommonTableProps}
          />
        </Paper>

        <Paper radius="md" p="lg" shadow="sm">
          <Title order={5} mb="xl">
            Sponsor Modules
          </Title>

          <Table
            data={data.filter(d => d.editable === false)}
            columns={cols}
            rowActionsInclude={['view']}
            rowActionsTooltipLabels={{ view: 'View module' }}
            displayColumnDefOptions={{ 'mrt-row-actions': { size: 60 } }}
            {...CommonTableProps}
          />
        </Paper>
      </TableContext.Provider>
    </Screen>
  )
})

enum ColId {
  Title = 'title',
  Topic = 'topic',
  SubTopic = 'sub-topic',
  EskName = 'esk-name',
  Status = 'status',
  SecondaryActions = 'secondary-actions',
}

const columnHelper = createMRTColumnHelper<GetContentModulesResponse[0]>()
const cols: MRT_ColumnDef<GetContentModulesResponse[0]>[] = [
  columnHelper.accessor('title', {
    id: ColId.Title,
    header: 'Title',
    grow: true,
  }),
  columnHelper.accessor(row => row.categories?.[0] ?? '', {
    id: ColId.Topic,
    header: 'Topic',
    grow: false,
  }),
  columnHelper.accessor(row => row.mappings?.join(', ') ?? '', {
    id: ColId.SubTopic,
    header: 'Sub-Topic',
    grow: false,
  }),
  columnHelper.accessor(
    row => {
      const { esks } = useTableContext()
      const selectEsk = row.selectEsk
      return esks.find(e => e.eskId === selectEsk)?.name ?? `Member Sidekick ${selectEsk ?? ''}`
    },
    {
      id: ColId.EskName,
      header: '',
      grow: false,
      size: 138,
      Header: ({ column }) => (
        <HeaderCellWithTooltip column={column} header={<>Sidekick</>} tooltip="The Sidekick assigned to a module" />
      ),
    },
  ),
  columnHelper.accessor('status', {
    id: ColId.Status,
    header: 'Status',
    grow: false,
    size: 152,
    Cell: props => {
      const status = props.cell.getValue() ?? 'draft'
      const inProcess = props.row.original.inProcess

      return (
        <Group gap="xs" wrap="nowrap">
          {inProcess ? (
            <Badge variant="filled" color="gray" rightSection={<Loader size="xs" color="white" type="dots" />}>
              Processing
            </Badge>
          ) : (
            <Badge
              variant="filled"
              color={
                {
                  published: 'green',
                  draft: 'orange',
                  removed: 'gray',
                  pending_approval: 'blue',
                  testing: 'yellow',
                  complete: 'blue', // TODO: remove once backend implements `pending_approval` status
                }[status]
              }>
              {
                // @ts-expect-error - `complete` status is deprecated
                status === 'complete' || status === 'pending_approval' ? 'Pending' : status
              }
            </Badge>
          )}
        </Group>
      )
    },
  }),
  columnHelper.display({
    id: ColId.SecondaryActions,
    header: 'Deploy',
    grow: false,
    size: 82,
    Cell: props => {
      const { changeModuleStatus } = useTableContext()
      const { authenticationStore } = useGlobalState()
      const { user } = authenticationStore
      
      const isSponsorAdmin = (): boolean => {
        return (user?.isSponsorAdmin || user?.isSponsor) ?? false
      }

      const editable = isSponsorAdmin() || props.row.original.editable !== false
      const status = props.row.original.status ?? 'draft'
      // const inProcess = props.row.original.inProcess
      const menuIconStyle = { width: theme.rem(18), height: theme.rem(18) }
      const changeStatus = (status: `${ContentModuleStatus}`) => () => changeModuleStatus(props.row.original, status)

      return (
        <Group gap="xs" justify="flex-end" flex="1 1 100%" wrap="nowrap">
          <Menu withArrow arrowPosition="center">
            <Menu.Target>
              <Box>
                <TableRowActionButton
                  action="changeStatus"
                  tooltipLabel="Manage the release of your Content Module"
                  // temporarily allow status change while in process due to backend bug
                  // actionButtonProps={{ disabled: !editable || !!inProcess }}
                  actionButtonProps={{ disabled: !editable }}
                />
              </Box>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Change Status:</Menu.Label>
              {isSponsorAdmin() ? (
                <>
                  <Menu.Item
                    leftSection={<IconArchive style={menuIconStyle} />}
                    disabled={status === 'draft'}
                    onClick={changeStatus('draft')}>
                    Unpublish
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<IconSend2 style={menuIconStyle} />}
                    disabled={status === 'published'}
                    onClick={changeStatus('published')}>
                    Publish
                  </Menu.Item>
                  <Menu.Item
                  color="red.7"
                  leftSection={<IconTrash style={menuIconStyle} />}
                  disabled={status === 'removed'}
                  onClick={changeStatus('removed')}>
                    Delete
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item
                    leftSection={<IconSettingsCog style={menuIconStyle} />}
                    disabled={status === 'testing'}
                    onClick={changeStatus('testing')}>
                    Test in App
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<IconClockShare style={menuIconStyle} />}
                    disabled={status === 'pending_approval'}
                    onClick={changeStatus('pending_approval')}>
                    Submit for Approval
                  </Menu.Item>
                  {status === 'draft' && (
                    <Menu.Item
                      color="red.7"
                      leftSection={<IconTrash style={menuIconStyle} />}
                      onClick={changeStatus('removed')}>
                      Delete
                    </Menu.Item>
                  )}
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        </Group>
      )
    },
  }),
]
