import { Button } from '@mantine/core'
import { IconArrowLeft, IconHexagons } from '@tabler/icons-react'
import type { DefaultError, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import type { RefObject } from 'react'
import { createContext, useContext, useEffect, useRef } from 'react'
import { useParams } from 'wouter'

import { CollapsibleContentSection } from '@/components/CollapsibleContentSection'
import { Screen } from '@/components/Screen'
import type { RouteParams } from '@/configs/routes'
import { useGlobalState } from '@/hooks/useGlobalState'
import type {
  GetContentModuleResponse,
  GetContentResponse,
  GetESKsResponse,
  GetResourcesResponse,
  GetStepsResponse,
} from '@/services/api/api.types'
import { navigateTo } from '@/utils/navigation'

import { NotFoundScreen } from '../404Screen'
import { ContentDataModal } from './ContentDataModal'
import { ModuleSection } from './ModuleSection'
import { StepSection } from './StepSection'

export type ModuleContentSection = {
  taskId?: string
  modal: RefObject<ContentDataModal | null>

  esksQuery: UseQueryResult<GetESKsResponse, DefaultError>
  cmQuery: UseQueryResult<GetContentModuleResponse, DefaultError>
  cmContentQuery: UseQueryResult<GetContentResponse, DefaultError>
  cmResourcesQuery: UseQueryResult<GetResourcesResponse, DefaultError>
  stepsQuery: UseQueryResult<GetStepsResponse, DefaultError>
}

const ModuleContentSectionContext = createContext<ModuleContentSection>({} as ModuleContentSection)
export const useContentModuleScreenData = () => useContext(ModuleContentSectionContext)

export const ContentModuleScreen = observer(function ContentModuleScreen() {
  const params = useParams<RouteParams['contentModule']>()

  const modal = useRef<ContentDataModal>(null)

  const { api, uiStore } = useGlobalState()

  const taskId = params.id

  const esksQuery = useQuery({
    queryKey: ['cm', 'esks'],
    queryFn: api.getESKs,
    initialData: [],
  })

  const cmQuery = useQuery({
    queryKey: ['cm', taskId],
    queryFn: () => api.getContentModule({ task_id: taskId! }),
  })

  const cmContentQuery = useQuery({
    queryKey: ['cm', 'content', taskId],
    queryFn: () => api.getContent({ task_id: taskId! }),
    enabled: cmQuery.isSuccess,
  })

  const cmResourcesQuery = useQuery({
    queryKey: ['cm', 'resources', taskId],
    queryFn: () => api.getResources({ task_id: taskId! }),
    enabled: cmQuery.isSuccess,
  })

  const stepsQuery = useQuery({
    queryKey: ['steps', taskId],
    queryFn: () => api.getSteps({ task_id: taskId! }),
    enabled: cmQuery.isSuccess,
  })

  useEffect(() => {
    if (cmQuery.isSuccess || cmQuery.isError) {
      uiStore.hideLoadingOverlay()
    } else {
      uiStore.showLoadingOverlay()
    }
  }, [cmQuery.isSuccess, cmQuery.isError])

  const common = { taskId, modal }

  const operations = {
    esksQuery,
    cmQuery,
    cmContentQuery,
    cmResourcesQuery,
    stepsQuery,
  }

  if (cmQuery.isError) {
    return <NotFoundScreen />
  }

  return (
    <Screen
      title={['Previewing Module', cmQuery.data?.title].filter(Boolean).join(': ')}
      icon={IconHexagons}
      description="Use this page to preview Content Modules created by other SMEs. You will only be able to modify the content if you created it."
      BodyStackProps={{ gap: 'xxl' }}>
      <ModuleContentSectionContext.Provider value={{ ...common, ...operations }}>
        <ModuleSection />
        {stepsQuery.data?.map((s, i) => <StepSection key={s.stepId} index={i} step={s} />)}
        {/* <ModuleFAQSection /> */}
        {/* <ModuleResourcesSection /> */}
        <ContentDataModal ref={modal} />
        <CollapsibleContentSection
          id="actions"
          ContentElement={
            <Button onClick={() => navigateTo('contentModules')} leftSection={<IconArrowLeft />}>
              Back to Modules
            </Button>
          }
        />
      </ModuleContentSectionContext.Provider>
    </Screen>
  )
})
