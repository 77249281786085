import type { ButtonProps } from '@mantine/core'
import { Button } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useLongPress } from 'use-long-press'

import { Consts } from '@/configs/consts'
import { useGlobalState } from '@/hooks/useGlobalState'
import { ModalStatus } from '@/services/state/UIStore'

import { version } from '../../package.json'

export const VersionButton = observer(function VersionButton(props: ButtonProps) {
  const { uiStore, configStore } = useGlobalState()

  const versionLongPressProps = useLongPress(() => uiStore.setProp('devModalStatus', ModalStatus.Open), {
    threshold: Consts.isDev ? 1 : 5000,
  })

  return (
    <Button size="sm" color="gray" variant="transparent" {...versionLongPressProps()} {...props}>
      v{[version, configStore.serverEnv].filter(Boolean).join('-')}
    </Button>
  )
})
