import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import type { BaseLocationHook } from 'wouter'
import { Router as WRouter } from 'wouter'
import { useBrowserLocation } from 'wouter/use-browser-location'

import { useGlobalState } from '@/hooks/useGlobalState'

export const Router = observer(function Router(props: PropsWithChildren) {
  const { uiStore } = useGlobalState()
  const { routerLocationChangeFn } = uiStore

  const useInterruptibleLocation: BaseLocationHook = () => {
    const [location, setLocation] = useBrowserLocation()

    return [
      location,
      async path => {
        if (!!routerLocationChangeFn) {
          const response = await routerLocationChangeFn(path)
          if (response === false) return
        }

        setLocation(path)
      },
    ]
  }

  return <WRouter hook={useInterruptibleLocation}>{props.children}</WRouter>
})
