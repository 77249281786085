import type { LineChartSeries } from '@mantine/charts'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { colors } from '@/configs/colors'
import type { LineChartEntry, TimeChartEntry, TimeSeriesResponse, TimeSeriesResult } from '@/services/api/api.types'
import { sanitizeLabel } from '@/utils/analytics'

export const usePhTimeSeriesData = (
  queryKey: string[],
  queryFn: () => Promise<TimeSeriesResponse>,
): TimeSeriesResult => {
  const { data, isLoading, error } = useQuery({ queryKey, queryFn })
  const [total, setTotal] = useState(0)
  const [series, setSeries] = useState<LineChartSeries[]>([])
  const [chartData, setChartData] = useState<LineChartEntry[]>([])

  const toLocalDateString = (date: string): string => {
    return new Date(date).toLocaleDateString()
  }

  const createTimeChartData = (entries: TimeChartEntry[]): LineChartEntry[] => {
    const results = entries.map(({ date, items }) => ({
      // Convert dates from UTC (server timezone) into local timezone strings
      date: toLocalDateString(date),
      // Convert the items into key-value pairs and add them to the entry
      ...Object.fromEntries(items.map(({ label, value }) => [sanitizeLabel(label), value])),
    }))
    // Sort the entries by date
    return results.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
  }

  const createSeries = (labels: string[]): LineChartSeries[] => {
    return labels.map((label, index) => ({
      name: sanitizeLabel(label),
      color: colors.chartColors[index % colors.chartColors.length],
    })) as LineChartSeries[]
  }

  useEffect(() => {
    if (data) {
      setTotal(data.total)
      // Create the series for the line chart
      setSeries(createSeries(data.labels))
      // Create the timeline data for the line chart
      setChartData(createTimeChartData(data.data))
    }
  }, [data])

  return { total, series, data: chartData, isLoading, error }
}
