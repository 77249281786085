import { Box, Collapse, Group, Paper, Stack, Text, Title } from '@mantine/core'
import { IconChevronRight, IconHelpCircle } from '@tabler/icons-react'
import type { ComponentType, ReactNode } from 'react'
import { useMemo, useState } from 'react'

import { Screen } from '@/components/Screen'
import { helpContent } from '@/configs/helpContent'
import type { HelpEntry } from '@/configs/helpContent.types'
import { theme } from '@/configs/theme'
import * as classes from '@/styles/CollapsibleContentSection.css'

export function HelpScreen() {
  const email = 'SMEhelp@sidekickwellness.com'

  return (
    <Screen title="FAQs" icon={IconHelpCircle} BodyStackProps={{ gap: 'xl' }}>
      <Paper p="xl" radius="md" withBorder>
        <Group align="flex-start" w="100%">
          <Title order={6} fw={600} w={'25%'} style={{ flexShrink: 0 }}>
            Need Help?
          </Title>
          <Stack pr="xl" style={{ flex: 1 }}>
            <Text>
              Here are the answers to some of our most frequently asked questions. If you still haven&apos;t found what
              you&apos;re looking for, please contact the Sidekick Customer Success team at{' '}
              <Text component="a" href={`mailto:${email}`} c="blue">
                {email}
              </Text>
            </Text>
          </Stack>
        </Group>
      </Paper>

      {helpContent.map(section => (
        <Stack key={section.title}>
          <Title order={4}>{section.title}</Title>
          {section.subSections.map((subSection, index) => (
            <Stack key={index} gap="md">
              {subSection.title ? (
                <Title order={6} c="dimmed">
                  {subSection.title}
                </Title>
              ) : null}
              {subSection.entries.map(entry => (
                <CollapsibleHelpSection
                  key={entry.question}
                  title={entry.question}
                  ContentElement={<HelpEntryAnswer entry={entry} />}
                  defaultCollapsed
                />
              ))}
            </Stack>
          ))}
        </Stack>
      ))}
    </Screen>
  )
}

type HelpEntryAnswerProps = {
  entry: HelpEntry
}

function HelpEntryAnswer({ entry }: HelpEntryAnswerProps) {
  return (
    <Stack px="xl">
      <Text>{entry.answer}</Text>
      {entry.steps?.map((step, index) => (
        <Text key={step} pl="md">
          {index + 1}. {step}
        </Text>
      ))}
    </Stack>
  )
}

type CollapsibleHelpSectionProps = {
  title?: ReactNode
  ContentComponent?: ComponentType
  ContentElement?: ReactNode
  /**
   * Whether the section is initially opened
   * @default false
   */
  defaultCollapsed?: boolean
  skipTimelineBullet?: boolean
  hidden?: boolean
  onToggle?: (nextCollapsed: boolean) => void
}

function CollapsibleHelpSection(props: CollapsibleHelpSectionProps) {
  const { title, ContentComponent, ContentElement = null, defaultCollapsed = false, onToggle } = props
  const [collapsed, setCollapsed] = useState(defaultCollapsed ?? false)

  const controls = useMemo(
    () => ({
      open: () => setCollapsed(false),
      close: () => setCollapsed(true),
      toggle: () => setCollapsed(prevState => !prevState),
    }),
    [],
  )

  return (
    <Paper radius="md" shadow="xs">
      {!!title && (
        <Group
          onClick={() => {
            onToggle?.(!collapsed)
            controls.toggle()
          }}
          className={theme.cx(classes.groupHeader, classes.groupHeaderCollapsible)}>
          <Stack gap={0}>
            <Text size="md" fw={600}>
              {title}
            </Text>
          </Stack>

          <IconChevronRight
            className={theme.cx([classes.groupHeaderCaret, !collapsed && classes.groupHeaderCaretRotated])}
            stroke={1.5}
          />
        </Group>
      )}

      <Collapse in={!collapsed}>
        <Box px="lg" pb="md">
          {ContentComponent ? <ContentComponent /> : ContentElement}
        </Box>
      </Collapse>
    </Paper>
  )
}
