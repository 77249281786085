import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import type { HistogramEntry, HistogramResponse, HistogramResult } from '@/services/api/api.types'
import { sanitizeLabel } from '@/utils/analytics'

export const usePhHistogramData = (queryKey: string[], queryFn: () => Promise<HistogramResponse>): HistogramResult => {
  const { data, isLoading, error } = useQuery({ queryKey, queryFn })
  const [histogramData, setHistogramData] = useState<HistogramEntry[]>([])

  useEffect(() => {
    if (data) {
      // Sanitize the labels first
      const sanitizedData = data.data.map(entry => ({
        ...entry,
        label: sanitizeLabel(entry.label),
      }))
      setHistogramData(sanitizedData)
    }
  }, [data])

  return { data: histogramData, isLoading, error }
}
