import { Box, Image, Stack } from '@mantine/core'
import { IconChartColumn } from '@tabler/icons-react'

import tmpUsageScreenPlaceholder from '@/assets/images/tmp-usage-screen-placeholder.png'
import { ComingSoon } from '@/components/ComingSoon'
import InsightsCharts from '@/components/InsightsCharts'
import { Screen } from '@/components/Screen'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'

export function MetricsScreen() {
  const { authenticationStore } = useGlobalState()
  const { user } = authenticationStore

  const isSponsorAdmin = (): boolean => {
    // TODO: Verify this is the correct way to check if the user is a sponsor admin
    return (user?.isSponsorAdmin || user?.isSponsor) ?? false
  }

  return (
    <Screen title="Metrics" icon={IconChartColumn}>
      {isSponsorAdmin() ? (
        <Box pos="relative">
          <InsightsCharts />
        </Box>
      ) : (
        <Box pos="relative">
          <Image src={tmpUsageScreenPlaceholder} />

          <Stack
            pos="absolute"
            w="100%"
            h="100%"
            justify="space-around"
            align="center"
            top={0}
            left={0}
            style={{ pointerEvents: 'none' }}>
            {Array.from({ length: 3 }, (_v, i) => (
              <ComingSoon
                key={i}
                withParens={false}
                size={theme.rem(100)}
                fw={700}
                opacity={0.3}
                IconProps={{ size: 100 }}
              />
            ))}
          </Stack>
        </Box>
      )}
    </Screen>
  )
}
