import { type GetContentModulesResponse } from '@/services/api/api.types'

/**
 * Custom chart tick wrapper for the Content Module chart, this allows us to use rich text styling for the tick labels
 * @param cmData - The list of content modules for the sponsor
 * @returns A function that returns a custom tick component
 */
interface CMTickWrapperProps {
  cmData: GetContentModulesResponse
}

function CMTickWrapper({ cmData }: CMTickWrapperProps) {
  // Helper function to get the Content Module name from the taskId
  const idToCmName = (id: string) => {
    const contentModule = cmData.find(cm => cm.taskId === id)
    return contentModule?.title ?? 'Untitled Module'
  }

  return function CMTick({ x, y, payload }: { x: number; y: number; payload: { value: string } }) {
    const taskId = payload.value
    const cmName = idToCmName(taskId)

    return (
      <g
        transform={`translate(${x},${y})`}
        textAnchor="end"
        dominantBaseline="middle"
        style={{
          fontSize: '12px',
        }}>
        <text
          dy="-0.5em"
          style={{
            fill: '#333',
            fontWeight: 'bold',
          }}>
          {cmName}
        </text>
        <text
          dy="1.2em"
          style={{
            fill: '#666',
            fontSize: '10px',
          }}>
          {taskId}
        </text>
      </g>
    )
  }
}

export default CMTickWrapper
