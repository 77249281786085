export const Consts = {
  isDev: import.meta.env.DEV,
  isProd: import.meta.env.PROD,

  // Defaults loaded from .env file if it exists.
  // Some or all of these might be overridden by the config.json fetched in ConfigStore.
  serverBaseUrl: import.meta.env.VITE_SERVER_BASE_URL,
  analyticsServerBaseUrl: import.meta.env.VITE_ANALYTICS_SERVER_BASE_URL,
  posthogHost: import.meta.env.VITE_POSTHOG_HOST,
  posthogKey: import.meta.env.VITE_POSTHOG_KEY,
} as const
