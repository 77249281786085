import { Alert, Button, PasswordInput, Stack, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { getHotkeyHandler } from '@mantine/hooks'
import { IconAlertOctagon } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'
import { usePostHog } from 'posthog-js/react'
import { parse } from 'qs'
import { useMemo, useState } from 'react'
import { useSearch } from 'wouter'
import { z } from 'zod'

import { useGlobalState } from '@/hooks/useGlobalState'
import { navigateTo } from '@/utils/navigation'

const LoginSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().min(8, 'Must be at least 8 characters long'),
})

export const LoginScreen = observer(function LoginScreen() {
  const posthog = usePostHog()
  const searchParamsString = useSearch()
  const searchParams: { email?: string } = parse(searchParamsString)

  const { authenticationStore, uiStore } = useGlobalState()
  const [authError, setAuthError] = useState<string | null>(null)

  const form = useForm<z.infer<typeof LoginSchema>>({
    mode: 'controlled',
    validate: zodResolver(LoginSchema),
    initialValues: {
      email: searchParams.email ?? '',
      password: '',
    },
  })

  const { logIn } = authenticationStore

  const onKeyboardEnter = useMemo(() => getHotkeyHandler([['Enter', () => void submitForm()]]), [])

  async function submitForm() {
    setAuthError(null)

    const validations = form.validate()

    if (validations.hasErrors) {
      setAuthError('Double check your email and password.')
      return
    }

    uiStore.showLoadingOverlay('relative')

    const response = await logIn(form.getValues())

    uiStore.hideLoadingOverlay()

    if (!response.ok) {
      if (response.code === 'NEW_PASSWORD_REQUIRED') {
        navigateTo('changePassword', { searchParams: { email: form.getValues().email } })
        return
      }

      setAuthError(response.message)
    } else {
      posthog?.capture('login_success', { $set: { email: form.getValues().email }, skPlatform: 'portal' })
    }
  }

  return (
    <Stack gap="xxl" maw={768} w="100%">
      <Title order={4} ta="center" fw={500}>
        Hello from Sidekick!
      </Title>

      {authError && <Alert variant="light" color="red" title={authError} icon={<IconAlertOctagon />}></Alert>}

      <TextInput withAsterisk label="Email address" onKeyDown={onKeyboardEnter} {...form.getInputProps('email')} />
      <PasswordInput withAsterisk label="Password" onKeyDown={onKeyboardEnter} {...form.getInputProps('password')} />

      <Stack align="center">
        <Button size="lg" onClick={() => void submitForm()} fullWidth>
          Login
        </Button>

        <Button
          variant="subtle"
          size="compact-lg"
          onClick={() =>
            navigateTo('changePassword', {
              searchParams: form.getValues().email ? { email: form.getValues().email } : null,
            })
          }>
          Change Password
        </Button>
      </Stack>
    </Stack>
  )
})
