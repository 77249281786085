import { Box, Button, Grid, Group, Modal, Text, Textarea, TextInput } from '@mantine/core'
import type { FileWithPath } from '@mantine/dropzone'
// import { IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { notifications } from '@mantine/notifications'
import { IconDeviceFloppy } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { AIActionButton } from '@/components/AIActionButton'
import { AIInputWrapper } from '@/components/AIInputWrapper'
import {
  acceptedFileUploadText,
  contentUploadMimeTypes,
  FileUpload,
  needsExtractionMimeTypes,
  rejectedFileUploadText,
} from '@/components/FileUpload'
// import { InputLabel } from '@/components/InputLabel'
import { Table } from '@/components/Table'
import { useFileUpload } from '@/hooks/useFileUpload'
// import { useGlobalState } from '@/hooks/useGlobalState'
import { useMockSMESKChatIntegration } from '@/hooks/useMockSMESKIntegration'
import { AddStepDataContentModal } from '@/screens/ContentModuleFormScreen/AddStepDataContentModal'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import { FAQTable } from '@/screens/ContentModuleFormScreen/FAQTable'
import { ResourcesTable } from '@/screens/ContentModuleFormScreen/ResourcesTable'
import type { GetContentResponse } from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import type { StepFormSchema } from '@/utils/contentModuleForm'
import { castStepToZodForm, castZodFormToStep } from '@/utils/contentModuleForm'
import { getRequiredFieldDescriptionProps } from '@/utils/forms'

export const StepModal = observer(function StepModal(props: CMFormSection) {
  // const { configStore } = useGlobalState()

  // const { uploading: titleImageUploading, upload: uploadTitleImage } = useFileUpload({
  //   onPrepareError: () =>
  //     props.stepForm.setFieldError('titleImage', 'Something went wrong. Please try again or contact support.'),
  //   onUploadError: () =>
  //     props.stepForm.setFieldError('titleImage', 'Upload failed. Please try again or contact support.'),
  //   onUploadSuccess: () => props.stepForm.setFieldError('titleImage', undefined),
  // })

  const { uploading: contentUploading, upload: uploadContent } = useFileUpload({
    onPrepareError: () =>
      props.stepForm.setFieldError('content.file', 'Something went wrong. Please try again or contact support.'),
    onUploadError: () =>
      props.stepForm.setFieldError('content.file', 'Upload failed. Please try again or contact support.'),
    onUploadSuccess: () => props.stepForm.setFieldError('content.file', undefined),
  })

  function openDataContentModal() {
    props.stepForm.setFieldValue('content.text', props.stepContentQuery.data?.content ?? '')

    props.openModal?.({
      title: `Edit Section Content Manually`,
      subtitle: 'Copy and paste or enter your content manually.',
      Component: AddStepDataContentModal,
    })
  }

  async function processDroppedFiles(files: FileWithPath[]) {
    const file = files[0]

    const uploadResponse = await uploadContent(file)

    if (!uploadResponse) return

    if (props.stepId) {
      if (needsExtractionMimeTypes.includes(file.type)) {
        await props.contentUpdateMutation?.mutateAsync({
          step_id: props.stepId!,
          file_name: file.name,
          s3_file: uploadResponse.fileName,
        })
      } else {
        await props.contentUpdateMutation?.mutateAsync({ step_id: props.stepId!, file })
      }
    } else {
      const lastStep = props.stepsQuery.data?.[props.stepsQuery.data.length - 1]

      if (needsExtractionMimeTypes.includes(file.type)) {
        await props.stepCreateMutation?.mutateAsync({
          task_id: props.taskId!,
          file_name: file.name,
          s3_file: uploadResponse.fileName,
        })
      } else {
        await props.stepCreateMutation.mutateAsync({
          task_id: props.taskId!,
          preceding_step_id: lastStep?.stepId,
          file,
        })
      }
    }
  }

  async function updateStep() {
    const validate = props.stepForm.validateField

    const validations = [validate('name'), validate('title'), validate('introduction')]

    if (validations.some(v => v.hasError)) {
      notifications.show({
        title: 'Could Not Submit Form',
        message: 'There was an issue submitting section details. Please check the form for errors.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })

      return
    }

    props.stepUpdateMutation.mutate(
      {
        ...castZodFormToStep(props.stepForm.getValues()),
        step_id: props.stepId!,
      },
      {
        onSuccess: () => props.closeModal(() => props.stepForm.setValues(castStepToZodForm())),
      },
    )
  }

  useEffect(() => {
    props.stepForm.clearErrors()

    if (props.stepId && (props.contentUpdateMutation.isSuccess || props.stepCreateMutation.isSuccess)) {
      props.generateMetadataMutation?.mutate({ step_id: props.stepId!, number_of_options: 1 })
    }
  }, [props.stepId, props.stepCreateMutation.isSuccess, props.contentUpdateMutation.isSuccess])

  const { askSMESKForField } = useMockSMESKChatIntegration<StepFormSchema>(props)

  const isSectionContentMutating =
    contentUploading || props.stepCreateMutation.isPending || props.contentUpdateMutation.isPending

  const hasContent = !!props.stepContentQuery.data
  const isMutating = props.stepUpdateMutation.isPending || isSectionContentMutating
  const contentLoading = isSectionContentMutating || props.stepContentQuery.isFetching

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={12}>
            <FileUpload
              withAsterisk
              label="Section Content"
              activateOnClick={false}
              loading={contentLoading}
              onDrop={processDroppedFiles}
              error={props.stepForm.getInputProps('content.file').error}
              titles={{
                idle: `${hasContent ? 'Replace' : 'Upload'} Section Content`,
                reject: rejectedFileUploadText,
              }}
              accept={contentUploadMimeTypes}
              maxFiles={1}
              primaryActionLabel={`Browse and ${hasContent ? 'Update' : 'Upload'} File`}
              secondaryActionLabel={`${hasContent ? 'Edit' : 'Add'} Content Manually`}
              onSecondaryActionClick={openDataContentModal}
              hints={{ idle: acceptedFileUploadText }}
              TopSlot={
                hasContent && (
                  <Table
                    data={props.stepContentQuery.data ? [props.stepContentQuery.data!] : []}
                    columns={contentCols}
                    rowActionsInclude={['edit']}
                    enableTopToolbar={false}
                    mantinePaperProps={{ className: classes.fileUploadTable }}
                    enableTableHead={false}
                    onRowActionClick={action => {
                      if (action === 'edit') {
                        openDataContentModal()
                      }
                    }}
                    displayColumnDefOptions={{ 'mrt-row-actions': { size: 60 } }}
                  />
                )
              }
            />
            {contentLoading && (
              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt="sm">
                <Text c="dimmed" className={classes.inputDescription}>
                  Processing content, this may take a few minutes...
                </Text>
              </Box>
            )}
          </Grid.Col>

          {hasContent && (
            <>
              <Grid.Col span={12}>
                <AIInputWrapper
                  disabled={props.stepForm.isDirty('name')}
                  generating={props.generateMetadataMutation.isPending}
                  value={props.metadataForm.getValues().step?.name}
                  clearValue={() => props.metadataForm.setFieldValue('step.name', null)}>
                  <TextInput
                    withAsterisk
                    label="Section Title"
                    rightSection={
                      <AIActionButton
                        variant="action-button"
                        onClick={() =>
                          askSMESKForField({
                            type: 'step',
                            userChatFieldLabel: 'Section Title',
                            formField: 'name',
                            apiParamField: 'name',
                            apiResponseField: 'name',
                            actions: ['accept', 'regenerate'],
                          })
                        }
                      />
                    }
                    {...props.stepForm.getInputProps('name')}
                    {...getRequiredFieldDescriptionProps(props.stepForm, 'name', 'Name of your section.')}
                  />
                </AIInputWrapper>
              </Grid.Col>

              <Grid.Col span={6}>
                <AIInputWrapper
                  disabled={props.stepForm.isDirty('title')}
                  generating={props.generateMetadataMutation.isPending}
                  value={props.metadataForm.getValues().step?.title}
                  clearValue={() => props.metadataForm.setFieldValue('step.title', null)}>
                  <Textarea
                    withAsterisk
                    label="Section Overview"
                    autosize
                    resize="vertical"
                    minRows={8}
                    rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
                    rightSection={
                      <AIActionButton
                        variant="action-button"
                        onClick={() =>
                          askSMESKForField({
                            type: 'step',
                            userChatFieldLabel: 'Section Overview',
                            formField: 'title',
                            apiParamField: 'title',
                            apiResponseField: 'title',
                            actions: ['accept', 'regenerate'],
                          })
                        }
                      />
                    }
                    {...props.stepForm.getInputProps('title')}
                    {...getRequiredFieldDescriptionProps(
                      props.stepForm,
                      'title',
                      'This is how Sidekicks will respond to inquiries about what the section covers.',
                    )}
                  />
                </AIInputWrapper>
              </Grid.Col>

              <Grid.Col span={6}>
                <AIInputWrapper
                  disabled={props.stepForm.isDirty('introduction')}
                  generating={props.generateMetadataMutation.isPending}
                  value={props.metadataForm.getValues().step?.introduction}
                  clearValue={() => props.metadataForm.setFieldValue('step.introduction', null)}>
                  <Textarea
                    withAsterisk
                    label="Section Intro"
                    autosize
                    resize="vertical"
                    minRows={8}
                    rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
                    rightSection={
                      <AIActionButton
                        variant="action-button"
                        onClick={() =>
                          askSMESKForField({
                            type: 'step',
                            userChatFieldLabel: 'Section Intro',
                            formField: 'introduction',
                            apiParamField: 'introduction',
                            apiResponseField: 'introduction',
                            actions: ['accept', 'regenerate'],
                          })
                        }
                      />
                    }
                    {...props.stepForm.getInputProps('introduction')}
                    {...getRequiredFieldDescriptionProps(
                      props.stepForm,
                      'introduction',
                      'This is how the Sidekick will first introduce this section to the user.',
                    )}
                  />
                </AIInputWrapper>
              </Grid.Col>

              {/* <Grid.Col span={12}>
                {props.stepForm.getValues().titleImage ? (
                  <Box>
                    <InputLabel label="Section Header Image" />

                    <Indicator
                      color="red"
                      size={36}
                      withBorder
                      label={<IconTrash size={22} className={classes.tableRowIcon} stroke={1.5} />}
                      styles={{ indicator: { cursor: 'pointer' } }}
                      onClick={() => props.stepForm.setFieldValue('titleImage', null)}>
                      <Image
                        radius="md"
                        src={`${configStore.serverBaseUrl}/files/${props.stepForm.getValues().titleImage}`}
                        h="auto"
                        w="100%"
                      />
                    </Indicator>

                    <Text c="dimmed" className={classes.inputDescription}>
                      This image will be displayed when the section is first introduced. You can add more images in
                      supplemental resources.
                    </Text>
                  </Box>
                ) : (
                  <FileUpload
                    label="Section Header Image"
                    activateOnClick={false}
                    loading={titleImageUploading}
                    maxFiles={1}
                    error={props.stepForm.getInputProps('titleImage').error}
                    titles={{ idle: 'Upload Image' }}
                    accept={IMAGE_MIME_TYPE}
                    primaryActionLabel="Select File"
                    onDrop={async files => {
                      const file = files[0]

                      const uploadResponse = await uploadTitleImage(file)

                      if (!uploadResponse) return

                      props.stepForm.setFieldValue('titleImage', uploadResponse.fileName)
                    }}
                    description="This image will be displayed when the section is first introduced. You can add more images in supplemental resources."
                    hint="Drag and drop image file here or click below to browse. File should be in .jpg, .png, or .gif formats."
                  />
                )}
              </Grid.Col> */}

              {/* <Grid.Col span={6}>
                <AIInputWrapper
                  disabled={props.stepForm.isDirty('section_objectives')}
                  generating={props.generateStepObjectivesMutation.isPending}
                  value={props.metadataForm.getValues().stepObjectives}
                  clearValue={() => props.metadataForm.setFieldValue('stepObjectives', undefined)}>
                  <Textarea
                    label="Section Objectives"
                    autosize
                    resize="vertical"
                    minRows={8}
                    {...props.stepForm.getInputProps('section_objectives')}
                    {...getRequiredFieldDescriptionProps(
                      props.stepForm,
                      'section_objectives',
                      'The Sidekick will use these objectives to proactively guide the user through the section.',
                    )}
                    classNames={{ input: classes.mskIntroductionInput }}
                    rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
                    rightSection={
                      <AIActionButton
                        variant="action-button"
                        onClick={() => askSMESKForStepObjectives({ actions: ['accept', 'regenerate'] })}
                      />
                    }
                  />
                </AIInputWrapper>
              </Grid.Col> */}

              <Grid.Col span={12}>
                <FAQTable {...props} type="step" />
              </Grid.Col>

              <Grid.Col span={12}>
                <ResourcesTable {...props} type="step" />
              </Grid.Col>
            </>
          )}
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          {hasContent && (
            <Button leftSection={<IconDeviceFloppy />} onClick={updateStep} loading={isMutating}>
              Save and Exit
            </Button>
          )}

          {!isMutating && (
            <Button
              size="sm"
              variant="light"
              color="gray"
              onClick={() => props.closeModal(() => props.stepForm.setValues(castStepToZodForm()))}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
})

const contentCols: MRT_ColumnDef<GetContentResponse>[] = [
  {
    id: 'content',
    header: '',
    grow: true,
    Cell: props => {
      return (
        <Text size="sm" fw={500} lineClamp={6}>
          {props.row.original?.content ?? ''}
        </Text>
      )
    },
  },
]

//     {/*
// // ARCHIVED: As per Courtney, this field is not needed.
// <Grid.Col span={6}>
//   <Textarea
//     withAsterisk
//     label="Section Overview"
//     description="This is how the Expert Sidekick will respond to inquiries about what the section covers. Max: 400 characters."
//     autosize
//     resize="vertical"
//     minRows={8}
//     maxLength={400}
//     rightSectionProps={{ className: commonStyles.textareaRightSectionWithAIActionButton }}
//     rightSection={
//       <AIActionButton
//         variant="action-button"
//         onClick={() =>
//           askSMESKForField({
//             type: 'step',
//             userChatFieldLabel: 'Section Overview',
//             formField: 'shortDescription',
//             apiParamField: 'short_description',
//             apiResponseField: 'shortDescription',
//             actions: ['accept', 'regenerate'],
//           })
//         }
//       />
//     }
//     {...props.form.getInputProps('shortDescription')}
//   />
// </Grid.Col> */}

//       {/*
// // ARCHIVED: As per Courtney, this field is not needed.
// <Grid.Col span={12}>
//   <Radio.Group
//     withAsterisk
//     label="Delivery Preference"
//     {...props.form.getInputProps('deliveryMode')}
//     value={String(props.form.getValues().step?.deliveryMode)}
//     onChange={e => {
//       props.form.setFieldValue('deliveryMode', Number(e) as z.infer<typeof props.stepSchema>['deliveryMode'])
//     }}>
//     <Grid gutter="xl">
//       <Grid.Col span={6}>
//         <RadioCard
//           value={String(props.stepSchema.shape.deliveryMode.enum.Paraphrase)}
//           label="Paraphrase"
//           description="When sharing content with users, Sidekick can paraphrase information (recommended)."
//         />
//       </Grid.Col>

//       <Grid.Col span={6}>
//         <RadioCard
//           value={String(props.stepSchema.shape.deliveryMode.enum.Quote)}
//           label="Quote"
//           description="When sharing content with users, Sidekick must only directly quote from the content files."
//         />
//       </Grid.Col>
//     </Grid>
//   </Radio.Group>
// </Grid.Col> */}

//         {/*
// // ARCHIVED: As per Courtney, this field is not needed.
// <Grid.Col span={12}>
//   <Radio.Group
//     label={<ComingSoon prefix="Privacy Setting " />}
//     description="This setting applies to this section only and will override the module privacy setting chosen in Step 3."
//     inputWrapperOrder={['label', 'description', 'input', 'error']}
//     {...props.form.getInputProps('access')}
//     onChange={e => {
//       props.form.setFieldValue('access', e as z.infer<typeof props.stepSchema>['access'])
//       // updateCM(['access'])() // NOT IMPLEMENTED
//     }}>
//     <Group gap="xl">
//       <RadioCard
//         value={props.stepSchema.shape.access.enum.open}
//         label="Open"
//         description="The user's Sidekick can sit in on the user's conversation about this section."
//       />

//       <RadioCard
//         value={props.stepSchema.shape.access.enum.restricted}
//         label="Restricted"
//         description="The section contains proprietary and confidential information."
//       />
//     </Group>
//   </Radio.Group>
// </Grid.Col> */}
