import { BarChart, LineChart } from '@mantine/charts'
import { Group, Paper, Stack, Tabs, Text, Title } from '@mantine/core'
import { IconEyeExclamation, IconPencil } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { colors } from '@/configs/colors'
import { useGlobalState } from '@/hooks/useGlobalState'
import { usePhHistogramData } from '@/hooks/usePhHistogramData'
import { usePhTimeSeriesData } from '@/hooks/usePhTimeSeries'

import CMTickWrapper from './ContentModuleChartTick'
import CMTooltip from './ContentModuleTooltip'

const TabKeys = {
  loginLineChart: 'loginLineChart',
  cmEditLineChart: 'cmEditLineChart',
  cmEditBarChart: 'cmEditBarChart',
} as const

export default function InsightsCharts() {
  const { api, analyticsApi } = useGlobalState()
  const [activeTab, setActiveTab] = useState<string | null>(TabKeys.loginLineChart)
  const {
    data: loginsPerSme,
    series: loginsPerSmeSeries,
    total: totalLogins,
  } = usePhTimeSeriesData(['analytics', 'loginsPerSme'], () => analyticsApi.getLoginsPerSME({}))
  const {
    data: cmEditsPerSme,
    series: cmEditsPerSmeSeries,
    total: totalCmEdits,
  } = usePhTimeSeriesData(['analytics', 'cmEditsPerSme'], () => analyticsApi.getCmEditsPerSME({}))
  const { data: editsPerCm } = usePhHistogramData(['analytics', 'editsPerCm'], () => analyticsApi.getEditsPerCm({}))

  const cmQuery = useQuery({
    queryKey: ['cm', 'list'],
    queryFn: api.getContentModules,
    initialData: [],
    refetchInterval: 7 * 1000,
  })

  return (
    <Stack>
      <Title order={6} fw="bold">
        Summary
      </Title>
      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Group gap="xl">
          <Stack gap="xs">
            <IconEyeExclamation size={32} />
            <Text size="sm">SME Logins</Text>
            <Text fw="bold" size="lg">
              {totalLogins}
            </Text>
          </Stack>
          <Stack gap="xs">
            <IconPencil size={32} />
            <Text size="sm">CM Edits</Text>
            <Text fw="bold" size="lg">
              {totalCmEdits}
            </Text>
          </Stack>
        </Group>
      </Paper>
      <Paper radius="md" p="lg" shadow="sm" px="xl">
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TabKeys.loginLineChart} leftSection={<IconEyeExclamation size={16} />}>
              SME Logins
            </Tabs.Tab>
            <Tabs.Tab value={TabKeys.cmEditLineChart} leftSection={<IconPencil size={16} />}>
              SME Edits
            </Tabs.Tab>
            <Tabs.Tab value={TabKeys.cmEditBarChart} leftSection={<IconPencil size={16} />}>
              CM Edits
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={TabKeys.loginLineChart} py="md">
            <Title order={6} mb="md">
              Number of Logins by Subject Matter Experts
            </Title>
            {activeTab === TabKeys.loginLineChart && (
              <LineChart h={300} data={loginsPerSme} dataKey="date" series={loginsPerSmeSeries} curveType="linear" />
            )}
          </Tabs.Panel>
          <Tabs.Panel value={TabKeys.cmEditLineChart} py="md">
            <Title order={6} mb="md">
              Number of Edits by Subject Matter Experts
            </Title>
            {activeTab === TabKeys.cmEditLineChart && (
              <LineChart h={300} data={cmEditsPerSme} dataKey="date" series={cmEditsPerSmeSeries} curveType="linear" />
            )}
          </Tabs.Panel>
          <Tabs.Panel value={TabKeys.cmEditBarChart} py="md">
            <Title order={6} mb="md">
              Total Edits to Each Content Module
            </Title>
            {activeTab === TabKeys.cmEditBarChart && (
              <BarChart
                h={300}
                w={`calc(100% - 150px)`}
                pl={150}
                data={editsPerCm}
                dataKey="label"
                orientation="vertical"
                minBarSize={10}
                maxBarWidth={50}
                series={[{ name: 'count', color: colors.chartColors[0] }]}
                gridAxis="none"
                yAxisProps={{
                  tick: CMTickWrapper({ cmData: cmQuery.data }),
                }}
                tooltipProps={{
                  content: ({ label, payload }) => CMTooltip({ label, payload, cmData: cmQuery.data }),
                }}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Stack>
  )
}
