import { Box, Button, Grid, Group, Modal, Radio, Stack, Text, Textarea, TextInput } from '@mantine/core'
import { IMAGE_MIME_TYPE, MIME_TYPES } from '@mantine/dropzone'
import { IconDeviceFloppy, IconExternalLink } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { useEffect } from 'react'
import type { z } from 'zod'

import { AIActionButton } from '@/components/AIActionButton'
import { AIInputWrapper } from '@/components/AIInputWrapper'
import type { FileUploadProps } from '@/components/FileUpload'
import { acceptedFileUploadText, contentUploadMimeTypes, FileUpload, needsExtractionMimeTypes } from '@/components/FileUpload'
import { RadioCard } from '@/components/RadioCard'
import { Table } from '@/components/Table'
import { useFileUpload } from '@/hooks/useFileUpload'
import { useGlobalState } from '@/hooks/useGlobalState'
import { useMockSMESKChatIntegration } from '@/hooks/useMockSMESKIntegration'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { ResourceDataContentModalProps } from '@/screens/ContentModuleFormScreen/ResourceDataContentModal'
import { ResourceDataContentModal } from '@/screens/ContentModuleFormScreen/ResourceDataContentModal'
import * as commonClasses from '@/styles/componentCommons.css'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import type { ResourceFormSchema } from '@/utils/contentModuleForm'
import { castZodFormToResource, resourceSchema } from '@/utils/contentModuleForm'
import { getRequiredFieldDescriptionProps } from '@/utils/forms'

type ResourceZodType = z.infer<ResourceFormSchema>

export type ResourceModalProps = {
  type: 'cm' | 'step'
}

const fileUploadProps: Record<NonNullable<ResourceZodType>['type'], Partial<FileUploadProps>> = {
  text: {
    titles: { idle: 'Upload File' },
    accept: contentUploadMimeTypes  ,
    primaryActionLabel: 'Select File',
  },
  url: {},
  video: {
    titles: { idle: 'Upload Video' },
    accept: [MIME_TYPES.mp4],
    primaryActionLabel: 'Select Video',
  },
  image: {
    titles: { idle: 'Upload Image' },
    accept: [...IMAGE_MIME_TYPE],
    primaryActionLabel: 'Select Image',
  },
}

export function ResourceModal(props: CMFormSection & ResourceModalProps) {
  const { configStore } = useGlobalState()

  const { uploading, upload } = useFileUpload({
    onPrepareError: () =>
      props.resourceForm.setFieldError('file', 'Something went wrong. Please try again or contact support.'),
    onUploadError: () =>
      props.resourceForm.setFieldError('file', 'Upload failed. Please try again or contact support.'),
    onUploadSuccess: () => props.resourceForm.setFieldError('file', undefined),
  })

  const { askSMESKForField } = useMockSMESKChatIntegration<ResourceFormSchema>(props)

  function openResourceContentModal() {
    props.openModal?.({
      title: `${!props.resourceForm.getValues().data ? 'New' : 'Edit'} Resource Content Entry`,
      subtitle: 'Copy and paste or enter your content manually.',
      Component: ResourceDataContentModal,
      ComponentProps: { onSubmit: submitResource } satisfies ResourceDataContentModalProps,
    })
  }

  async function validateAndSubmitResource() {
    const values = props.resourceForm.getValues()
    const validate = props.resourceForm.validateField

    const validations = [
      validate('type'),
      props.resourceId && validate('title'),
      props.resourceId && validate('description'),
      values.type === 'url' && validate('dataUrl'),
      values.type === 'text' && (values.file ? validate('file') : validate('data')),
      (values.type === 'image' || values.type === 'video') && validate('data'),
    ].filter(Boolean)

    if (validations.some(v => v && v.hasError)) {
      return
    }

    await submitResource()

    if (props.resourceId) {
      props.closeModal()
    }
  }

  async function submitResource() {
    const values = props.resourceForm.getValues()

    const idParams = props.type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }
    const params = castZodFormToResource(values)

    if (props.resourceId) {
      // update
      await props.resourceUpdateMutation?.mutateAsync({ ...params, ...idParams, resource_id: props.resourceId })
    } else {
      // create
      await props.resourceCreateMutation?.mutateAsync({ ...params, ...idParams })
    }
  }

  const isMutating = props.resourceCreateMutation?.isPending || props.resourceUpdateMutation?.isPending
  const hasData = props.resourceForm.getValues().data || props.resourceForm.getValues().file
  const typeIsMedia = props.resourceForm.getValues().type === 'image' || props.resourceForm.getValues().type === 'video'
  const dataIsYoutube = props.resourceForm
    .getValues()
    .dataUrl?.match(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
    )

  useEffect(() => {
    if (props.resourceId && !typeIsMedia && !dataIsYoutube) {
      props.generateMetadataMutation?.mutate({ resource_id: props.resourceId!, number_of_options: 1 })
    }
  }, [props.resourceId])

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={12}>
            <Radio.Group
              withAsterisk
              label="Type"
              description="The type determines how the resource will be used."
              {...props.resourceForm.getInputProps('type')}
              onChange={e => {
                props.resourceForm.setFieldValue('type', e as z.infer<ResourceFormSchema>['type'])

                props.resourceForm.setFieldValue('data', '')
                props.resourceForm.setFieldValue('dataUrl', '')
                props.resourceForm.setFieldValue('file', null)

                props.resourceForm.setFieldError('data', '')
                props.resourceForm.setFieldError('dataUrl', '')
                props.resourceForm.setFieldError('file', '')

                props.metadataForm.setFieldValue('resource', undefined)
              }}>
              <Group justify="space-between" gap="xl" align="stretch">
                <RadioCard
                  label="Additional Information"
                  description="Sidekick will commit this information to memory and share with users when appropriate."
                  value={resourceSchema.shape.type.enum.text}
                />
                <RadioCard
                  label="Supplemental URL"
                  description="Sidekick will display this website for users when appropriate."
                  value={resourceSchema.shape.type.enum.url}
                />
                <RadioCard
                  label="Supplemental Video"
                  description="Sidekick will play this video file for users when appropriate."
                  value={resourceSchema.shape.type.enum.video}
                />
                <RadioCard
                  label="Supplemental Image"
                  description="Sidekick will display this image file for users when appropriate."
                  value={resourceSchema.shape.type.enum.image}
                />
              </Group>
            </Radio.Group>
          </Grid.Col>

          <Grid.Col span={12}>
            {props.resourceForm.getValues().type === 'url' ? (
              <TextInput
                withAsterisk
                label="URL"
                description="The URL of the resource."
                {...props.resourceForm.getInputProps('dataUrl')}
                {...getRequiredFieldDescriptionProps(props.resourceForm, 'dataUrl', 'The URL of the resource.')}
              />
            ) : (
              <FileUpload
                withAsterisk
                label="Resource File"
                activateOnClick={false}
                loading={uploading || isMutating}
                maxFiles={1}
                error={
                  props.resourceForm.getValues().type !== 'url'
                    ? props.resourceForm.getInputProps('file').error || props.resourceForm.getInputProps('data').error
                    : undefined
                }
                {...fileUploadProps[props.resourceForm.getValues().type ?? 'text']}
                secondaryActionLabel={props.resourceForm.getValues().type === 'text' ? 'Enter Manually' : undefined}
                onSecondaryActionClick={openResourceContentModal}
                hints={{
                  idle:
                    props.resourceForm.getValues().type === 'text'
                      ? acceptedFileUploadText
                      : `Drag and drop media files here or click below to browse.`,
                }}
                onDrop={async files => {
                  props.resourceForm.setFieldValue('file', null)
                  props.resourceForm.setFieldValue('data', '')
                  props.resourceForm.setFieldValue('fileName', '')

                  props.resourceForm.setFieldError('file', '')
                  props.resourceForm.setFieldError('data', '')

                  const file = files[0]

                  if (props.resourceForm.getValues().type === 'text') {
                    if (needsExtractionMimeTypes.includes(file.type)) {
                      const uploadResponse = await upload(file)
                      if (!uploadResponse) return

                      props.resourceForm.setFieldValue('s3File', uploadResponse.fileName)
                      props.resourceForm.setFieldValue('fileName', file.name)
                    } else {
                      props.resourceForm.setFieldValue('file', files[0])
                      props.resourceForm.setFieldValue('fileName', files[0].name)
                    }
                  } else {
                    const uploadResponse = await upload(file)
                    if (!uploadResponse) return

                    props.resourceForm.setFieldValue('data', uploadResponse.fileName)
                    props.resourceForm.setFieldValue('fileName', file.name)
                  }

                  void submitResource()
                }}
                BottomSlot={
                  hasData && (
                    <Table
                      data={hasData ? [props.resourceForm.getValues()] : []}
                      columns={contentCols}
                      rowActionsInclude={row => {
                        if (row.original.type === 'text') {
                          return ['edit']
                        } else {
                          return ['view']
                        }
                      }}
                      enableTopToolbar={false}
                      enableTableHead={false}
                      mantinePaperProps={{ className: classes.fileUploadTable }}
                      onRowActionClick={(action, row) => {
                        if (action === 'edit') {
                          openResourceContentModal()
                        } else if (action === 'view') {
                          window.open(`${configStore.serverBaseUrl}/files/${row.original.data}`, '_blank')
                        }
                      }}
                      displayColumnDefOptions={{ 'mrt-row-actions': { size: 60 } }}
                    />
                  )
                }
              />
            )}
          </Grid.Col>

          {!!props.resourceId && (
            <>
              <Grid.Col span={6}>
                <Stack gap="xxxl">
                  <AIInputWrapper
                    disabled={typeIsMedia || props.resourceForm.isDirty('title')}
                    generating={props.generateMetadataMutation.isPending}
                    value={props.metadataForm.getValues().resource?.title}
                    clearValue={() => props.metadataForm.setFieldValue('resource.title', null)}>
                    <TextInput
                      withAsterisk
                      label="Title"
                      rightSection={
                        !typeIsMedia &&
                        !dataIsYoutube && (
                          <AIActionButton
                            variant="action-button"
                            onClick={async () => {
                              if (props.resourceForm.isDirty('dataUrl')) {
                                await submitResource()
                                props.resourceForm.resetDirty()
                              }

                              askSMESKForField({
                                type: 'resource',
                                userChatFieldLabel: 'Title',
                                formField: 'title',
                                apiParamField: 'title',
                                apiResponseField: 'title',
                                actions: ['accept', 'regenerate'],
                              })
                            }}
                          />
                        )
                      }
                      {...props.resourceForm.getInputProps('title')}
                      {...getRequiredFieldDescriptionProps(props.resourceForm, 'title', 'Title of the resource.')}
                    />
                  </AIInputWrapper>

                  {/* <TagsInput
                    allowDuplicates
                    label="Keywords"
                    placeholder="Type keyword and press Enter..."
                    description="Keywords help the system know when to recommend this resource."
                    {...props.resourceForm.getInputProps('keywords')}
                  /> */}
                </Stack>
              </Grid.Col>

              <Grid.Col span={6}>
                <AIInputWrapper
                  disabled={typeIsMedia || props.resourceForm.isDirty('description')}
                  generating={props.generateMetadataMutation.isPending}
                  value={props.metadataForm.getValues().resource?.description}
                  clearValue={() => props.metadataForm.setFieldValue('resource.description', null)}>
                  <Textarea
                    withAsterisk
                    label="Description"
                    autosize
                    resize="vertical"
                    flex={1}
                    classNames={{ input: classes.resourceDescriptionInput }}
                    rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
                    rightSection={
                      !typeIsMedia &&
                      !dataIsYoutube && (
                        <AIActionButton
                          variant="action-button"
                          onClick={async () => {
                            if (props.resourceForm.isDirty('dataUrl')) {
                              await submitResource()
                              props.resourceForm.resetDirty()
                            }

                            askSMESKForField({
                              type: 'resource',
                              userChatFieldLabel: 'Description',
                              formField: 'description',
                              apiParamField: 'description',
                              apiResponseField: 'description',
                              actions: ['accept', 'regenerate'],
                            })
                          }}
                        />
                      )
                    }
                    {...props.resourceForm.getInputProps('description')}
                    {...getRequiredFieldDescriptionProps(
                      props.resourceForm,
                      'description',
                      'Brief description of file content.',
                    )}
                  />
                </AIInputWrapper>
              </Grid.Col>
            </>
          )}
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          {!props.resourceId && props.resourceForm.getValues().type === 'url' ? (
            <Button leftSection={<IconDeviceFloppy />} loading={isMutating} onClick={validateAndSubmitResource}>
              Save Resource URL
            </Button>
          ) : props.resourceId ? (
            <Button leftSection={<IconDeviceFloppy />} loading={isMutating} onClick={validateAndSubmitResource}>
              Save Supplemental Resource
            </Button>
          ) : null}

          {!isMutating && (
            <Button size="sm" variant="light" color="gray" onClick={() => props.closeModal()}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
}

const contentCols: MRT_ColumnDef<NonNullable<ResourceZodType>>[] = [
  {
    id: 'content',
    header: '',
    grow: true,
    Cell: props => {
      const { configStore } = useGlobalState()
      const { type } = props.row.original

      if (type === 'text') {
        return (
          <Text size="sm" lineClamp={2}>
            {props.row.original.data}
          </Text>
        )
      } else {
        return (
          <Group gap="xl">
            <Button
              rightSection={<IconExternalLink size={16} />}
              color="indigo.3"
              variant="filled"
              component="a"
              size="xs"
              tt="uppercase"
              target="_blank"
              href={`${configStore.serverBaseUrl}/files/${props.row.original.data}`}>
              Preview {type}
            </Button>

            <Text size="sm" fw={500} lineClamp={2}>
              {props.row.original.fileName}
            </Text>
          </Group>
        )
      }
    },
  },
]

// Removed per Jeff's request
// {props.resourceForm.getValues().type !== 'text' && (
//   <>
//     <Grid.Col span={6}>
//       <Textarea
//         withAsterisk
//         label={<ComingSoon prefix="ESK Intro " />}
//         description="This is how the file will be introduced to the ESK."
//         autosize
//         resize="vertical"
//         flex={1}
//         minRows={8}
//         rightSectionProps={{ className: commonStyles.textareaRightSectionWithAIActionButton }}
//         rightSection={
//           <AIActionButton
//             variant="action-button"
//             onClick={() => chatStore.submitMockSMESKChatFn?.('This feature is coming soon!')}
//           />
//         }
//       />
//     </Grid.Col>

//     <Grid.Col span={6}>
//       <Textarea
//         label={<ComingSoon prefix="Purpose " />}
//         description="The purpose of the file and how it will be used."
//         autosize
//         resize="vertical"
//         flex={1}
//         minRows={8}
//         rightSectionProps={{ className: commonStyles.textareaRightSectionWithAIActionButton }}
//         rightSection={
//           <AIActionButton
//             variant="action-button"
//             onClick={() => chatStore.submitMockSMESKChatFn?.('This feature is coming soon!')}
//           />
//         }
//       />
//     </Grid.Col>
//   </>
// )}
