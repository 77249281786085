import { Paper, Text } from '@mantine/core'

import type { GetContentModulesResponse } from '@/services/api/api.types'

/**
 * Custom tooltip for the Content Module chart, this allows us to use rich text styling for the tooltip labels
 * @param label - The label of the tooltip
 * @param payload - The payload of the tooltip
 * @param cmData - The list of content modules for the sponsor
 * @returns A custom tooltip component
 */
interface CMTooltipProps {
  label: string
  payload: Record<string, any>[] | undefined
  cmData: GetContentModulesResponse
}

function CMTooltip({ label, payload = [], cmData }: CMTooltipProps) {
  // Helper function to get the Content Module name from the taskId
  const idToCmName = (id: string) => {
    const contentModule = cmData.find(cm => cm.taskId === id)
    return contentModule?.title ?? 'Untitled Module'
  }

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text fw={500}>{idToCmName(label)}</Text>
      <Text c="dimmed" mb={5} fz="xs">
        {label}
      </Text>
      {payload?.map((item: any) => (
        <Text key={item.name} c={item.color}>
          {item.name}: {item.value}
        </Text>
      ))}
    </Paper>
  )
}

export default CMTooltip
