import type { FileWithPath } from '@mantine/dropzone'
import { notifications } from '@mantine/notifications'
import { useState } from 'react'

import { useGlobalState } from '@/hooks/useGlobalState'
import type { PrepareUploadResponse } from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'

type UseFileUploadArgs = {
  onPrepareError?: () => void
  onUploadError?: () => void
  onUploadSuccess?: () => void
}

const FileUpdloadExtensions = {
  text: /\.txt$/,
  markdown: /\.md$/,
  pdf: /\.pdf$/,
  doc: /\.(doc|docx)$/,
  powerpoint: /\.(ppt|pptx)$/,
} as const

const detectFileType = (file: FileWithPath) => {
  const matches = Object.entries(FileUpdloadExtensions).find(([_, pattern]) => file.path?.match(pattern))
  return matches ? matches[0] : null
}

export function useFileUpload(args?: UseFileUploadArgs): {
  uploading: boolean
  upload: (file: FileWithPath) => Promise<PrepareUploadResponse | undefined>
} {
  const { api } = useGlobalState()

  const { onPrepareError, onUploadError, onUploadSuccess } = args ?? {}

  const [uploading, setUploading] = useState<boolean>(false)

  async function upload(file: FileWithPath) {
    const fileType = detectFileType(file)
    if (fileType) {
      console.log(`useFileUpload: Uploading ${fileType} file - ${file.path}`)
    } else {
      console.log(`useFileUpload: Uploading unknown file type - ${file.path}`)
    }

    setUploading(true)

    const prepareResponse = await api.safeRequest(api.uploadPrepare, { fileType: file.type })

    const uploadProblemMessage = 'There was an issue preparing to the file. Please try again or contact support.'

    if (!prepareResponse.ok) {
      notifications.show({
        title: 'Something went wrong.',
        message: uploadProblemMessage,
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
      onPrepareError?.()
      setUploading(false)
      return
    }

    const uploadResponse = await api.safeRequest(api.upload, {
      file_path: prepareResponse.data.filePath,
      file_content: file,
    })

    if (!uploadResponse.ok) {
      notifications.show({
        title: 'Something went wrong.',
        message: uploadProblemMessage,
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
      onUploadError?.()
      setUploading(false)
      return
    }

    onUploadSuccess?.()

    setUploading(false)

    return prepareResponse.data
  }

  return { upload, uploading }
}
