import { Box, Button, Group, Image, Paper, Text } from '@mantine/core'
import { IconPhotoScan } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'

import { CollapsibleContentSection } from '@/components/CollapsibleContentSection'
import { EmptyState } from '@/components/EmptyState'
import { TableContentGrid } from '@/components/TableContentGrid'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetStepsResponse } from '@/services/api/api.types'

import { useContentModuleScreenData } from './ContentModuleScreen'
import { FAQSectionTable } from './ModuleFAQSection'
import { ModuleResourcesTable } from './ModuleResourcesSection'

type StepSectionProps = {
  step: GetStepsResponse[0]
  index: number
}

export function StepSection(props: StepSectionProps) {
  const data = useContentModuleScreenData()

  const { api, configStore } = useGlobalState()

  const stepContentQuery = useQuery({
    queryKey: ['step', 'content', props.step.stepId],
    queryFn: () => api.getContent({ step_id: props.step.stepId }),
    enabled: !!props.step.stepId,
  })

  const stepResourcesQuery = useQuery({
    queryKey: ['step', 'resources', props.step.stepId],
    queryFn: () => api.getResources({ step_id: props.step.stepId }),
    enabled: !!props.step.stepId,
  })

  return (
    <CollapsibleContentSection
      defaultCollapsed
      id={`step-${props.step.stepId}`}
      title={props.step.name ? `Section Details: ${props.step.name}` : `Section ${props.index + 1} Details`}
      ContentElement={
        <TableContentGrid
          headingFlexWidth="264px"
          rows={[
            { heading: 'Section Title', content: <Text fw={600}>{props.step.name}</Text> },
            {
              heading: 'Section Content',
              content: (
                <Box>
                  <Paper bg={theme.lighten(theme.colors.primaryColors[1], 0.7)} radius="sm" p="md" pb="xl" shadow="0">
                    <Text size="sm" fw={500} lineClamp={6}>
                      {stepContentQuery.data?.content}
                    </Text>
                  </Paper>
                  <Group justify="center">
                    <Button
                      size="sm"
                      style={{ marginTop: 'calc(var(--button-height-sm) / -2)' }}
                      onClick={() =>
                        data.modal.current?.openModal({
                          title: 'Previewing Section Content',
                          data: stepContentQuery.data?.content,
                          opts: { markdown: true },
                        })
                      }>
                      View Full Content
                    </Button>
                  </Group>
                </Box>
              ),
            },
            //{ heading: 'Section Overview', content: props.step.title },
            //{ heading: 'Section Intro', content: props.step.introduction },
            {
              heading: 'Section Header Image',
              content: props.step.titleImage ? (
                <Image
                  fit="contain"
                  radius="md"
                  h="auto"
                  mah="300px"
                  w="auto"
                  maw="100%"
                  src={`${configStore.serverBaseUrl}/files/${props.step.titleImage}`}
                />
              ) : (
                <EmptyState size="sm" Icon={IconPhotoScan} title="No Title Image Uploaded" />
              ),
            },
            {
              heading: 'Section FAQs',
              content: <FAQSectionTable data={props.step.faqs ?? []} />,
            },
            {
              heading: 'Section Supplemental Resources',
              content: <ModuleResourcesTable data={stepResourcesQuery.data ?? []} />,
            },
          ]}
        />
      }
    />
  )
}
