import { Button, Text } from '@mantine/core'
import type { FileWithPath } from '@mantine/dropzone'
import { IconAlignLeft2 } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { acceptedFileUploadText, contentUploadMimeTypes, FileUpload, needsExtractionMimeTypes, rejectedFileUploadText } from '@/components/FileUpload'
import { Table } from '@/components/Table'
import { useFileUpload } from '@/hooks/useFileUpload'
import { useGlobalState } from '@/hooks/useGlobalState'
import { AddDataContentModal } from '@/screens/ContentModuleFormScreen/AddDataContentModal'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { GetContentResponse } from '@/services/api/api.types'
import * as classes from '@/styles/ContentModuleFormScreen.css'

export const AddDataSection = observer(function AddDataSection(props: CMFormSection) {
  const { chatStore } = useGlobalState()

  const { uploading, upload } = useFileUpload({
    onPrepareError: () =>
      props.moduleForm.setFieldError('content.file', 'Something went wrong. Please try again or contact support.'),
    onUploadError: () =>
      props.moduleForm.setFieldError('content.file', 'Upload failed. Please try again or contact support.'),
    onUploadSuccess: () => props.moduleForm.setFieldError('content.file', undefined),
  })

  function openDataContentModal() {
    props.moduleForm.setFieldValue('content.text', props.cmContentQuery?.data?.content ?? '')

    props.openModal?.({
      title: `${!props.moduleForm.getValues().content.text ? 'New' : 'Edit'} Content Entry`,
      subtitle: 'Copy and paste or enter your content manually.',
      Component: AddDataContentModal,
    })
  }

  async function processDroppedFiles(files: FileWithPath[]) {
    const file = files[0]

    const uploadResponse = await upload(file)
    if (!uploadResponse) return

    if (props.taskId) {
      if (needsExtractionMimeTypes.includes(file.type)) {
        await props.contentUpdateMutation?.mutateAsync({
          task_id: props.taskId,
          file_name: file.name,
          s3_file: uploadResponse.fileName,
        })
      } else {
        await props.contentUpdateMutation?.mutateAsync({ task_id: props.taskId, file })
      }
    } else {
      if (needsExtractionMimeTypes.includes(file.type)) {
        await props.cmCreateMutation?.mutateAsync({ file_name: file.name, s3_file: uploadResponse.fileName })
      } else {
        await props.cmCreateMutation?.mutateAsync({ file })
      }
    }
  }

  useEffect(() => {
    if (!props.isNew) return

    const disposer = autorun(() => {
      chatStore.submitMockSMESKChatFn?.(undefined, undefined, { typing: true, skipQueue: true })
      chatStore.submitMockSMESKChatFn?.(
        "Let's start by uploading a file. This should be a text or markdown file. If you prefer to enter the data manually, you can do that too:",
        [
          <Button
            key="open-data-content-modal"
            size="xs"
            leftSection={<IconAlignLeft2 stroke={1.5} size={20} />}
            onClick={openDataContentModal}>
            Enter Data Manually
          </Button>,
        ],
      )
    })

    return disposer
  }, [props.isNew])

  const isMutating =
    uploading ||
    props.cmCreateMutation.isPending ||
    props.contentUpdateMutation.isPending

  const hasContent = !!props.cmContentQuery?.data

  return (
    <FileUpload
      withAsterisk
      label="Primary Content File"
      activateOnClick={false}
      loading={isMutating || props.cmContentQuery.isFetching}
      error={props.moduleForm.getInputProps('content.file').error}
      onDrop={processDroppedFiles}
      titles={{
        idle: hasContent ? 'Replace File' : 'Upload File',
        reject: rejectedFileUploadText,
      }}
      accept={contentUploadMimeTypes}
      maxFiles={1}
      secondaryActionLabel="Enter Manually"
      onSecondaryActionClick={openDataContentModal}
      hints={{ idle: acceptedFileUploadText }}
      BottomSlot={
        hasContent && (
          <Table
            data={hasContent ? [props.cmContentQuery!.data!] : []}
            columns={contentCols}
            rowActionsInclude={['edit']}
            enableTopToolbar={false}
            mantinePaperProps={{ className: classes.fileUploadTable }}
            enableTableHead={false}
            onRowActionClick={action => {
              if (action === 'edit') {
                openDataContentModal()
              }
            }}
            displayColumnDefOptions={{ 'mrt-row-actions': { size: 60 } }}
          />
        )
      }
    />
  )
})

const contentCols: MRT_ColumnDef<GetContentResponse>[] = [
  {
    id: 'content',
    header: '',
    grow: true,
    Cell: props => {
      return (
        <Text size="sm" fw={500} lineClamp={6}>
          {props.row.original.content}
        </Text>
      )
    },
  },
]
