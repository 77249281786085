import * as Sentry from '@sentry/react'
import { type IDisposer, onSnapshot } from 'mobx-state-tree'
import { createContext } from 'react'

import { Consts } from '@/configs/consts'
import { Api } from '@/services/api/api'
import type { RootStoreEnvironment } from '@/services/state/RootStore'
import { type RootStore, RootStoreModel, type RootStoreSnapshot } from '@/services/state/RootStore'
import { excludePathsFromObject } from '@/utils/objects'
import * as storage from '@/utils/storage'

const GLOBAL_STATE_PERSISTENCE_KEY = '@sidekick/state/v1'

let restoredGlobalState: Partial<RootStoreSnapshot> | undefined

try {
  restoredGlobalState = storage.load(GLOBAL_STATE_PERSISTENCE_KEY)
} catch (e) {
  Sentry.captureException(e)
}

export const globalEnv: RootStoreEnvironment = {
  api: new Api({ authToken: restoredGlobalState?.authenticationStore?.auth?.idToken }),
  analyticsApi: new Api({
    authToken: restoredGlobalState?.authenticationStore?.auth?.idToken,
    baseURL: Consts.analyticsServerBaseUrl,
  }),
}

export const rootStore = RootStoreModel.create(restoredGlobalState, globalEnv)
export const RootStoreContext = createContext<RootStore>(rootStore)

let _disposer: IDisposer | undefined

export function setupRootStore(store: RootStore) {
  let restoredGlobalState: Partial<RootStoreSnapshot> | undefined

  if (Consts.isDev) {
    console.tron.trackMstNode(store)
  }

  if (_disposer) _disposer()

  _disposer = onSnapshot(store, snapshot => {
    const persistedSnapshot = excludePathsFromObject(snapshot, [
      'uiStore',
      'devStore',
      'configStore',
      'authenticationStore.ready',
    ])

    storage.save(GLOBAL_STATE_PERSISTENCE_KEY, persistedSnapshot)
  })

  const unsubscribe = () => {
    _disposer?.()
    _disposer = undefined
  }

  return { rootStore: store, restoredGlobalState, unsubscribe }
}
