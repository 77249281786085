import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { getMembers, types } from 'mobx-state-tree'

import type { Api } from '@/services/api/api'
import { AuthenticationStoreModel } from '@/services/state/AuthenticationStore'
import { ChatStoreModel } from '@/services/state/ChatStore'
import { ConfigStoreModel } from '@/services/state/ConfigStore'
import { DevStoreModel } from '@/services/state/DevStore'
import { withEnv } from '@/services/state/helpers/withEnv'
import { UIStoreModel } from '@/services/state/UIStore'
import { getObjectKeys } from '@/utils/objects'

export const RootStoreModel = types
  .model('RootStore')
  .props({
    authenticationStore: types.optional(AuthenticationStoreModel, {}),
    uiStore: types.optional(UIStoreModel, {}),
    configStore: types.optional(ConfigStoreModel, {}),
    chatStore: types.optional(ChatStoreModel, {}),
    devStore: types.optional(DevStoreModel, {}),
  })
  .extend(withEnv)
  .views(self => ({
    get isAppReady() {
      return self.configStore.ready && self.authenticationStore.ready
    },
  }))
  .actions(self => ({
    async reset() {
      return await Promise.all(
        getObjectKeys(getMembers(self).properties).map(prop => {
          // @ts-expect-error -- `prop` doesn't have the correct key types
          return self[prop]?.reset?.()
        }),
      )
    },
  }))

export interface RootStore extends Instance<typeof RootStoreModel> {}
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
export interface RootStoreEnvironment {
  api: Api
  analyticsApi: Api
}
