import '@fontsource/noto-sans/latin.css'
import '@fontsource/noto-sans/latin-italic.css'
import '@fontsource/noto-sans-mono/latin.css'
import '@mantine/core/styles.css'
import '@mantine/dropzone/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'
import 'mantine-react-table/styles.css'
import '@mantine/charts/styles.css'
import '@/styles/global.css'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'

import { setupSentry } from '@/configs/sentry'

import App, { FallBackApp } from './src/App'
import { setupReactotron } from './src/configs/reactotron'

setupReactotron()
setupSentry()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Sentry.ErrorBoundary fallback={props => <FallBackApp {...props} />}>
    <App />
  </Sentry.ErrorBoundary>,
)
