import { Badge, Box, Button, Group, Stack, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconHierarchy3 } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { useEffect, useState } from 'react'

// import { RadioCard } from '@/components/RadioCard'
import { Table } from '@/components/Table'
// import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import { StepModal } from '@/screens/ContentModuleFormScreen/StepModal'
import type { GetStepsResponse } from '@/services/api/api.types'
// import * as classes from '@/styles/ContentModuleFormScreen.css'
import { castStepToZodForm } from '@/utils/contentModuleForm'
import { notifications } from '@mantine/notifications'
import * as commonClasses from '@/styles/componentCommons.css'
import { ComingSoon } from '@/components/ComingSoon'

// enum SplittingMode {
//   Unknown = 'unknown',
//   Single = 'single',
//   Auto = 'auto',
//   Manual = 'manual',
// }

export function StepsSection(props: CMFormSection) {
  const { uiStore } = useGlobalState()

  // const [splittingMode, setSplittingMode] = useState<SplittingMode>(SplittingMode.Unknown)
  const [hasSteps, setHasSteps] = useState(false)

  useEffect(() => {
    setHasSteps(!!props.stepsQuery.data?.length)
  }, [props.stepsQuery.data])

  useEffect(() => {
    // When we've just created a new content module, we need to add a step
    if (uiStore.pendingStepCreation && props.taskId) {
      uiStore.setPendingStepCreation(false)
      stageStep()
      openStepModal()
    }
  }, [props.taskId, uiStore.pendingStepCreation])

  function openStepModal() {
    props.openModal?.({
      title: `Set Up Section`,
      subtitle: 'Upload content and give a bit more information so your Expert Sidekick knows how to deliver your material.',
      Component: StepModal,
    })
  }

  const addNewContent = async () => {
    try {
      // Create CM with empty content first
      if (!props.taskId) {
        uiStore.setPendingStepCreation(true)
         await props.cmCreateMutation?.mutateAsync({ content: ' ' })
      } else {
        // If we already have a taskId, just create the step
        stageStep()
        openStepModal()
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to create new section. Please try again.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
    }
  }

  // async function handleSplittingModeSelection(v: SplittingMode) {
  //   setSplittingMode(v)

  //   if (v === SplittingMode.Single) {
  //     uiStore.showLoadingOverlay()

  //     await props.stepCreateMutation.mutateAsync({
  //       task_id: props.taskId!,
  //       content: props.cmContentQuery.data?.content ?? '',
  //     })

  //     uiStore.hideLoadingOverlay()

  //     // open the model of successful creation - the mutation handles the step staging
  //     openStepModal()

  //     setSplittingMode(SplittingMode.Unknown)
  //   }
  // }

  async function deleteStep(data: GetStepsResponse['0']) {
    modals.openConfirmModal({
      title: 'Delete content?',
      centered: true,
      children: (
        <Text size="sm">
          {`Are you sure you want to delete this content? This action is destructive you will lose all of the progress made
          for this content.`}
        </Text>
      ),
      labels: { confirm: 'Delete Content', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      groupProps: { mt: 'xl' },
      onConfirm: () => {
        props.stepForm.setValues(castStepToZodForm())
        props.stepDeleteMutation?.mutate({ step_id: data.stepId, task_id: props.taskId! })
      },
    })
  }

  function stageStep(data?: GetStepsResponse['0']) {
    props.stepForm.setValues(castStepToZodForm(data))
    props.stepForm.resetDirty()
    props.stepForm.resetTouched()
  }

  return (
    <Stack gap="xxxl">
      {/* {!hasSteps && (
        <Box>
          <InputLabel
            label="Section Creator"
            description="Choose whether you want the system to organize your content into sections or if you want to do it yourself. Single section is easiest, but multiple sections will help the Sidekick guide users through your content."
            withAsterisk
          />

          <Grid gutter="xl" align="stretch" mt={theme.rem(8)}>
            <Grid.Col span={4}>
              <Button
                onClick={() => handleSplittingModeSelection(SplittingMode.Single)}
                fullWidth
                size="lg"
                classNames={classes.buttonWithSecondaryText}>
                Single Section
                <Text component="span" size="xs">
                  All content in one section
                </Text>
              </Button>
            </Grid.Col>

            <Grid.Col span={4}>
              <Stack gap="xs" align="center">
                <AIActionButton
                  variant="button"
                  onClick={() => handleSplittingModeSelection(SplittingMode.Auto)}
                  size="lg"
                  fullWidth
                  disabled
                  classNames={classes.buttonWithSecondaryText}>
                  Multiple Sections
                  <Text component="span" size="xs">
                    Suggest sections automatically.
                  </Text>
                </AIActionButton>
                <ComingSoon />
              </Stack>
            </Grid.Col>

            <Grid.Col span={4}>
              <Stack gap="xs" align="center">
                <Button
                  onClick={() => handleSplittingModeSelection(SplittingMode.Manual)}
                  size="lg"
                  fullWidth
                  disabled
                  classNames={classes.buttonWithSecondaryText}>
                  DIY Sections
                  <Text component="span" size="xs">
                    Create your sections manually.
                  </Text>
                </Button>
                <ComingSoon />
              </Stack>
            </Grid.Col>
          </Grid>
        </Box>
      )} */}

      <Box>
        {hasSteps && (
          <Table
            enableRowOrdering
            data={props.stepsQuery.data ?? []}
            columns={stepColDefs}
            rowActionsInclude={['edit', 'delete']}
            enableTopToolbar={false}
            mantineRowDragHandleProps={{}}
            onRowActionClick={(action, row) => {
              if (action === 'delete') {
                deleteStep(row.original)
              } else if (action === 'edit') {
                stageStep(row.original)
                openStepModal()
              }
            }}
            rowActionsTooltipLabels={{
              edit: 'Edit Section',
              delete: 'Delete Section',
            }}
            EmptyStateProps={{
              title: 'No Sections Added',
              description: `Build the outline for your module and fill each section with information.`,
              Icon: IconHierarchy3,
            }}
            displayColumnDefOptions={{ 'mrt-row-actions': { size: 98 } }}
          />
        )}

        <Group justify="center" mt="xl">
          <Stack gap="xs" align="center">
            <Button
              disabled={hasSteps}
              loading={props.cmContentQuery.isFetching || props.cmCreateMutation.isPending || props.stepCreateMutation.isPending}
              onClick={() => {
                addNewContent()
              }}>
                {hasSteps ? 'Add Another Section' : 'Add Content'}
              </Button>
            {hasSteps && <ComingSoon />}
          </Stack>
        </Group>

      </Box>

      {/* TODO: Re-enable when we have multiple sections */}
      {/* <Grid gutter="xxxl">
        <Grid.Col span={12}>
          <Radio.Group label="Module Flow" withAsterisk {...props.moduleForm.getInputProps('presentationMode')}>
            <Group gap="xl" align="stretch">
              <RadioCard
                value={moduleSchema.shape.presentationMode.enum.member_directed}
                label="User Directed"
                description="Users can access the content in any order."
              />

              <RadioCard
                value={moduleSchema.shape.presentationMode.enum.sequential}
                label={<ComingSoon prefix="Sequential " dimPrefix />}
                disabled
                description="Their first time through, the user must go through the content in the order it's laid out."
              />
            </Group>
          </Radio.Group>
        </Grid.Col>
      </Grid> */}

    </Stack>
  )
}

const stepColDefs: MRT_ColumnDef<GetStepsResponse['0'] & { valid: boolean }>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    Cell: props => (
      <Text size="sm" fw={500}>
        {props.renderedCellValue || 'Untitled'}
      </Text>
    ),
    size: 280,
    grow: true,
  },
  {
    id: 'faqs',
    header: '# of FAQs',
    accessorFn: row => row.faqs?.length ?? 0,
    grow: false,
    size: 100,
  },
  // {
  //   accessorKey: 'title',
  //   header: 'Section Overview',
  //   Cell: props => <Text size="sm">{props.renderedCellValue}</Text>,
  //   grow: true,
  // },
  {
    id: 'status',
    header: 'Status',
    grow: false,
    size: 158,
    Cell: props => {
      if (!props.row.original.valid) {
        return (
          <Badge color="red" variant="filled">
            Incomplete
          </Badge>
        )
      } else if (!props.row.original.faqs?.length) {
        return (
          <Badge color="yellow" variant="filled">
            Missing FAQs
          </Badge>
        )
      } else {
        return (
          <Badge color="green" variant="filled">
            Ready to Submit
          </Badge>
        )
      }
    },
  },
]
