import { Badge, Box, Button, Group, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconBook, IconExternalLink } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'

import { InputLabel } from '@/components/InputLabel'
import { Table } from '@/components/Table'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { ResourceModalProps } from '@/screens/ContentModuleFormScreen/ResourceModal'
import { ResourceModal } from '@/screens/ContentModuleFormScreen/ResourceModal'
import type { GetResourcesResponse } from '@/services/api/api.types'
import { castResourceToZodForm } from '@/utils/contentModuleForm'

interface ResourcesTableProps extends CMFormSection {
  type: 'cm' | 'step'
}

export function ResourcesTable(props: CMFormSection & ResourcesTableProps) {
  const { configStore } = useGlobalState()

  function openResourceModal(isNew: boolean) {
    props.openModal?.({
      title: `${isNew ? 'New' : 'Edit'} Supplemental Resource`,
      Component: ResourceModal,
      ComponentProps: { type: props.type } satisfies ResourceModalProps,
    })
  }

  function stageResource(resource?: GetResourcesResponse['0']) {
    props.resourceForm.setValues(castResourceToZodForm(resource))
    props.resourceForm.resetDirty()
    props.resourceForm.resetTouched()
  }

  function deleteResource(resource: GetResourcesResponse['0']) {
    const idParams = props.type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }

    modals.openConfirmModal({
      title: 'Delete Supplemental Resource?',
      centered: true,
      children: <Text size="sm">{`Are you sure you want to remove this resource from? This can't be undone.`}</Text>,
      labels: { confirm: 'Delete Resource', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      groupProps: { mt: 'xl' },
      zIndex: 1000, // Set a higher z-index than the parent modal
      onCancel: () => console.log('Cancel'),
      onConfirm: () => props.resourceDeleteMutation?.mutate({ resource_id: resource.resourceId, ...idParams }),
    })
  }

  const data = props.type === 'cm' ? props.cmResourcesQuery.data : props.stepResourcesQuery.data
  const label = props.type === 'cm' ? 'Supplemental Resources' : 'Supplemental Resources for Section'

  return (
    <Box>
      <InputLabel label={label} withDivider />

      <Table
        entity={{ singular: 'Supplemental Resource', plural: 'Supplemental Resources' }}
        enableTopToolbar={false}
        enableTableHead={!!data?.length}
        data={data ?? []}
        columns={resourceColumnDefs}
        rowActionsInclude={row => {
          if (row.original.type !== 'text') {
            return ['view', 'edit', 'delete']
          } else {
            return ['edit', 'delete']
          }
        }}
        onRowActionClick={(action, row) => {
          if (action === 'edit') {
            stageResource(row.original)
            openResourceModal(false)
          } else if (action === 'delete') {
            deleteResource(row.original)
          } else if (action === 'view') {
            const previewLinkBase = row.original.type === 'url' ? '' : `${configStore.serverBaseUrl}/files/`
            window.open(`${previewLinkBase}${row.original.data}`, '_blank')
          }
        }}
        rowActionsTooltipLabels={{
          view: `Preview resource`,
          edit: ' Edit or replace file',
          delete: 'Permanently delete',
        }}
        EmptyStateProps={{
          title: 'No Supplemental Resources Added',
          description: `Add supplemental content to help users understand the ${props.type === 'cm' ? 'module' : 'section'}.`,
          Icon: IconBook,
        }}
        displayColumnDefOptions={{ 'mrt-row-actions': { size: 138 } }}
      />

      <Group justify="center" mt="xl">
        <Button
          onClick={() => {
            stageResource()
            openResourceModal(true)
          }}>
          Add Supplemental Resource
        </Button>
      </Group>
    </Box>
  )
}

const resourceColumnDefs: MRT_ColumnDef<GetResourcesResponse['0'] & { valid: boolean }>[] = [
  {
    accessorKey: 'title',
    header: 'Title',
    grow: false,
  },
  {
    id: 'type',
    accessorFn: row => row.type?.toUpperCase(),
    header: 'Type',
    size: 100,
    grow: false,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    grow: true,
    Cell: props => (
      <Text size="sm" lineClamp={2}>
        {props.renderedCellValue}
      </Text>
    ),
  },
  {
    id: 'data',
    header: 'Resource',
    grow: true,
    Cell: props => {
      const { configStore } = useGlobalState()
      const type = props.row.original.type
      const previewLinkBase = type === 'url' ? '' : `${configStore.serverBaseUrl}/files/`

      if (type === 'text') {
        return (
          <Text size="sm" lineClamp={2}>
            {props.row.original.data}
          </Text>
        )
      } else {
        return (
          <Button
            rightSection={<IconExternalLink size={16} />}
            color="indigo.3"
            variant="filled"
            component="a"
            size="xs"
            tt="uppercase"
            target="_blank"
            href={`${previewLinkBase}${props.row.original.data}`}>
            Preview {type}
          </Button>
        )
      }
    },
  },
  {
    id: 'status',
    header: 'Status',
    grow: false,
    size: 158,
    Cell: props => {
      if (!props.row.original.valid) {
        return (
          <Badge color="red" variant="light">
            Incomplete
          </Badge>
        )
      } else {
        return (
          <Badge color="green" variant="light">
            Ready to Submit
          </Badge>
        )
      }
    },
  },
]
